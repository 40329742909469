import MainTitle from "../../../../components/MainTitle";
import illustartion from "../../../../assets/images/illustration-4.png";
import Fade from "react-reveal/Fade";
const AboutUsSection = (props) => {
  return (
    <div className=" 900px:mt-6">
      <div className="grid gap-6 py-16 900px:grid-cols-2 max-screen-width">
        <Fade bottom>
          <div className="flex flex-col justify-center order-2 ">
            <MainTitle main={"Leading Info"} secondary="About us" />
            <p className="mt-4 leading-6 text-gray-500">
              Soft-master Technologies(Pvt)Ltd (SMT) was founded in 2000. Since
              then the firm has provided high quality programming and project
              management services to clients in the IT related knowledge
              management, software development including Internet (Web
              development) arenas. Having been already established as a well
              reputed firm in Sri Lankan marketplace, SMT is now rapidly
              expanding with a team of seasoned IT professionals.
            </p>
          </div>
        </Fade>
        <Fade bottom>
          <div className="flex-center 900px:items-start">
            <img src={illustartion} className="w-6/12 900px:w-10/12" />
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default AboutUsSection;
