const SecondaryTitle = ({ title, className }) => {
  return (
    <div className={` text-[24px] ${className}`}>
      <h1 className=" capitalize font-bold">{title}</h1>
      <div className="grid gap-0.5 grid-flow-col items-start w-fit">
        <div className="w-8 h-1 rounded-full bg-primary group-hover:bg-white "></div>
        <div className="w-2 h-1 rounded-full bg-primary group-hover:bg-white "></div>
        <div className="w-4 h-1 rounded-full bg-primary group-hover:bg-white "></div>
      </div>
    </div>
  );
};

export default SecondaryTitle;
