import React, { useContext, useEffect, useRef, useState } from "react";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import Modal from "../../../../components/Modal";
import Overlay from "../../../../components/Overlay";
import SpinnerOveraly from "../../../../components/SpinnerOverlay";
import useHttp from "../../../../hooks/useHttp";
import AuthContext from "../../../../store/admin/AuthContext";

const AdminModal = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { error, isLoading, sendRequest, validationErros } = useHttp();
  const authContext = useContext(AuthContext);

  useEffect(() => {
    const getAdmin = async () => {
      if (props.admin != null) {
        setValues(props.admin);
      }
    };
    getAdmin();
  }, [props.admin]);

  const onSubmit = async (event) => {
    event.preventDefault();

    const response = await sendRequest({
      url: props.admin ? "/admin/admins/" + props.admin.id : "/admin/admins",
      method: props.admin ? "PUT" : "POST",
      body: {
        name: name,
        email: email,
        password: password,
      },
      token: authContext.token,
    });

    if (response != null) {
      if (props.admin == null) {
        resetValues();
      }
      props.addAlert(
        "success",
        props.admin ? "Updated" : "Created",
        `Admin ${props.admin ? "Updated" : "Created"} Successfully`
      );
      props.onComplete();
    }
  };

  const resetValues = () => {
    setName("");
    setEmail("");
  };

  const setValues = (admin) => {
    setName(admin.name);
    setEmail(admin.email);
  };

  return (
    <Modal
      show={props.show}
      title={`${props.admin ? "Update" : "Create"} Admin`}
      onClose={() => {
        resetValues();
        props.onClose();
      }}
    >
      <form onSubmit={onSubmit} className="">
        <div className="relative grid gap-6 600px:grid-cols-2">
          {isLoading && !error && <Overlay />}
          <Input
            model={name}
            set={setName}
            label="name"
            className="600px:col-span-2"
            error={validationErros?.errors?.name ?? null}
          />
          <Input
            model={email}
            set={setEmail}
            label="email"
            className="600px:col-span-2"
            error={validationErros?.errors?.email ?? null}
          />
          <Input
            model={password}
            set={setPassword}
            type="password"
            label={`${props.admin ? "new password" : "password"}`}
            className="600px:col-span-2"
            error={validationErros?.errors?.password ?? null}
          />
        </div>
        <div className="flex-center 600px:col-span-2 mt-4">
          <Button isLoading={isLoading}>
            {props.admin ? "Update" : "Create"} Admin
          </Button>
        </div>
      </form>
    </Modal>
  );
};
export default AdminModal;
