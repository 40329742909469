import React, { forwardRef, useEffect, useState } from "react";
import useUpdate from "../hooks/useUpdate";

let timeout;
// model,set
const Input = forwardRef(
  ({ inputClassName, placeholder, label, type, onChange, ...props }, ref) => {
    let showError = props.showError ?? true;
    const [value, setValue] = useState(props.model ?? "");

    useEffect(() => {
      setValue(props.model);
    }, [props.model]);
    return (
      <div className={props.className}>
        {label && (
          <label className="block mb-2 font-medium capitalize">{label}</label>
        )}
        <div className="relative ">
          <input
            {...props}
            placeholder={placeholder ?? "Enter " + label}
            type={type ?? "text"}
            ref={ref}
            className={`input ${
              props.error != null
                ? "border-red-500 pr-8"
                : "border-gray-300 focus:border-gray-500  "
            } ${props.readOnly && "bg-gray-100"} ${inputClassName}`}
            value={type != "file" ? value : undefined}
            onChange={(e) => {
              let val = e.target.value;
              if (type != "file") {
                setValue(val);
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                  props.set && props.set(val);
                }, 200);
              }

              onChange && onChange(e);
            }}
          />
          {props.error && showError && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 text-red-500 absolute right-2 top-[5px]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
              />
            </svg>
          )}
        </div>
        {props.error && showError && (
          <p className="text-xs mt-1 text-red-500">{props.error}</p>
        )}
      </div>
    );
  }
);

export default Input;
