import React from "react";
import { Link, Outlet } from "react-router-dom";
import Card from "../../../components/Card";
import logo from "../../../assets/images/logo.png";

const Authentication = (props) => {
  return (
    <div className="h-full flex-center bg-secondary relative">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="100%"
        height="100%"
        className="absolute top-0 left-0"
        preserveAspectRatio="none"
        viewBox="0 0 1440 560"
      >
        <g mask='url("#SvgjsMask1022")' fill="none">
          <path
            d="M0 0L631.93 0L0 138.6z"
            fill="rgba(255, 255, 255, .1)"
          ></path>
          <path
            d="M0 138.6L631.93 0L650.13 0L0 370.28z"
            fill="rgba(255, 255, 255, .075)"
          ></path>
          <path
            d="M0 370.28L650.13 0L984.77 0L0 443.26z"
            fill="rgba(255, 255, 255, .05)"
          ></path>
          <path
            d="M0 443.26L984.77 0L1094.7 0L0 520.94z"
            fill="rgba(255, 255, 255, .025)"
          ></path>
          <path
            d="M1440 560L1235.02 560L1440 466.17z"
            fill="rgba(0, 0, 0, .1)"
          ></path>
          <path
            d="M1440 466.17L1235.02 560L737.0899999999999 560L1440 222.25000000000003z"
            fill="rgba(0, 0, 0, .075)"
          ></path>
          <path
            d="M1440 222.25L737.09 560L307.75000000000006 560L1440 150.55z"
            fill="rgba(0, 0, 0, .05)"
          ></path>
          <path
            d="M1440 150.55L307.75 560L197.72 560L1440 66.79z"
            fill="rgba(0, 0, 0, .025)"
          ></path>
        </g>
        <defs>
          <mask id="SvgjsMask1022">
            <rect width="1440" height="560" fill="#ffffff"></rect>
          </mask>
        </defs>
      </svg>
      <Card className="overflow-hidden relative z-10  m-auto">
        <img src={logo} className="text-center mx-auto w-40 mb-2" />
        <Outlet />
      </Card>
    </div>
  );
};
export default Authentication;
