import React, { useEffect, useState, useContext } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";

import PageTitle from "../../../components/PageTitle";
import useHttp from "../../../hooks/useHttp";
import AuthContext from "../../../store/admin/AuthContext";
import SearchInput from "../../../components/SearchInput";
import PageTable from "../../../components/PageTable";

import AddButton from "../../../components/AddButton";
import Card from "../../../components/Card";
import Pagination from "../../../components/Pagination";
import {
  imageUrl,
  removeQueryParam,
  searchParamsToObject,
} from "../../../utils/Statics";
import ConfirmationModal from "../../../components/ConfirmationModal";

const WorksIndex = (props) => {
  const [deleteId, setDeleteId] = useState(null);
  const [paginationData, setPaginationData] = useState(null);
  const [works, setWorks] = useState([]);

  const { error, isLoading, sendRequest } = useHttp();
  const { token } = useContext(AuthContext);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const fetch = async () => {
    const response = await sendRequest({
      url: "/admin/works",
      token: token,
      queryParams: {
        page: searchParams.get("page") ?? 1,
        column: searchParams.get("column") ?? "",
        value: searchParams.get("value") ?? "",
      },
    });

    if (response != null) {
      setWorks(response.data?.length > 0 ? response.data : []);
      delete response.data;
      setPaginationData(response.last_page != 1 ? response : null);
    }
  };
  const deleteWork = async () => {
    setDeleteId(null);
    const response = await sendRequest({
      url: "/admin/works/" + deleteId,
      token: token,
      method: "delete",
    });

    if (response != null) {
      fetch();
    }
  };
  useEffect(() => {
    fetch();
  }, [searchParams]);

  return (
    <div className="p-4 900px:p-6">
      <ConfirmationModal
        message="Are you sure to delete this work. this remove the work from product"
        onClose={() => {
          setDeleteId(null);
        }}
        type="danger"
        onProcess={() => {
          deleteWork();
        }}
        show={deleteId != null}
      />

      <div className="flex items-center justify-between flex-wrap">
        <PageTitle className="mt-4 mr-10">works</PageTitle>
        <AddButton to={"create"} className="mt-4">
          Create Work
        </AddButton>
      </div>

      <SearchInput
        className="mt-6 "
        setSearchParams={setSearchParams}
        getSearchParams={() => searchParamsToObject(searchParams)}
        options={["id:ID", "name:Name", "service.name:service name"]}
      />
      <Card className="mt-4">
        <PageTable
          name="work"
          isLoading={isLoading}
          error={error}
          headers={["Name", "Service", "Company", "Action"]}
          body={works.map((work) => {
            return [
              work.name,
              work.service.name,
              work.company,
              <div className="grid gap-x-2.5 grid-flow-col justify-start items-center">
                <Link to={"edit/" + work.id}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-[22px] w-[22px] t-all text-gray-400 hover:text-blue-500 rounded-full"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="1.3"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                    />
                  </svg>
                </Link>
                <svg
                  onClick={() => setDeleteId(work.id)}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  className="h-[22px] w-[22px] t-all text-gray-400 hover:text-red-500 rounded-full"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  />
                </svg>
              </div>,
            ];
          })}
        />

        {paginationData && (
          <Pagination
            currentPage={searchParams.get("page") ?? 1}
            setCurrentPage={(page) =>
              setSearchParams({
                ...getSearchParams(),
                page: page,
              })
            }
            data={paginationData}
            path={location.pathname}
            searchParams={removeQueryParam("page", location.search)}
          />
        )}
      </Card>
    </div>
  );
};
export default WorksIndex;
