import { imageUrl } from "../utils/Statics";
import Card from "./Card";

const ClientCard = ({ client }) => {
  return (
    <Card className="relative h-32 overflow-hidden border-none shadow-none flex-center group">
      <img
        src={imageUrl(client.logo.path)}
        className="object-cover object-center max-w-full max-h-full"
      />
      <div className="absolute top-0 left-0 flex flex-col items-center w-full h-full p-4 transition-all duration-500 transform translate-y-full bg-white group-hover:translate-y-0">
        <h1 className="font-semibold text-center ">{client.name}</h1>
        <p className="mt-2 text-xs text-center text-gray-500">
          {client.description}
        </p>
      </div>
    </Card>
  );
};

export default ClientCard;
