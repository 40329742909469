import { Card as C } from "@mui/material";
import React, { forwardRef } from "react";

const Card = forwardRef((props, ref) => {
  return (
    // <C calsses={`p-6  ${props.className} `}>{props.children}</C>
    <div ref={ref} className={`${props.className} card  shadow `}>
      {props.children}
    </div>
  );
});
export default Card;
