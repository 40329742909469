import AlbumCard from "../../../components/AlbumCard";
import { useEffect, useState } from "react";
import Breadcrumb from "../../../components/Breadcrumb";
import useHttp from "../../../hooks/useHttp";
import src from "../../../assets/images/no-results.png";
import AlbumModal from "./partials/AlbumModal";
import PageSpinner from "../../../components/PageSpinner";
import SpinnerOveraly from "../../../components/SpinnerOverlay";

const Gallery = (props) => {
  const [selected, setSelected] = useState(null);

  const [albums, setAlbums] = useState([]);
  const { error, isLoading, sendRequest } = useHttp();

  const fetch = async () => {
    const response = await sendRequest({
      url: "/gallery/",
    });

    if (response != null) {
      setAlbums(response);
    }
  };
  useEffect(() => {
    fetch();
  }, []);
  return (
    <>
      <div className="">
        <AlbumModal
          show={selected != null}
          album={selected}
          onClose={() => {
            setSelected(null);
          }}
        />
        <Breadcrumb main={"Our Events"} secondary={"Gallery"} />
        <div className="relative min-h-32">
          {isLoading && <SpinnerOveraly />}
          <div className="my-8 max-screen-width">
            {albums.length == 0 && !isLoading && (
              <div className="mt-12 flex-center">
                <img src={src} className="h-20" />
                <h1 className="mt-4 mb-1 text-base font-semibold text-gray-900 ">
                  Sorry No Album Found :(
                </h1>
                <p className="text-gray-400">
                  We're sorry, we have no album for now
                </p>
              </div>
            )}
            <div className="grid gap-8 my-8 500px:grid-cols-2 950px:grid-cols-3 1200px:grid-cols-4 ">
              {albums.map((album) => (
                <AlbumCard album={album} onClick={() => setSelected(album)} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
