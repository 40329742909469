import React, { forwardRef } from "react";

// model,set
const Textarea = forwardRef((props, ref) => {
  return (
    <div className={props.className}>
      {props.label && (
        <label className="block mb-2 font-medium capitalize">
          {props.label}
        </label>
      )}
      <textarea
        readOnly={props.readOnly}
        placeholder={props.placeholder ?? "Enter " + props.label}
        ref={ref}
        rows={props.rows ?? "6"}
        type={props.type ?? "text"}
        className={`px-3 py-2 rounded-sm outline-none border text-gray-500 w-full   ${
          props.error != null
            ? "border-red-500"
            : "border-gray-300 hover:border-gray-500  focus:border-blue-500"
        } ${props.readOnly && "bg-gray-100"} ${props.inputClassName}`}
        value={props.model}
        onChange={(e) => {
          props.set(e.target.value);
          props.onChange && props.onChange(e);
        }}
      ></textarea>
      {props.error && (
        <p className="text-xs mt-0.5 text-red-500">{props.error}</p>
      )}
    </div>
  );
});

export default Textarea;
