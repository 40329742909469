import { useEffect, useState } from "react";
import PageSpinner from "../../../components/PageSpinner";
import SpinnerOveraly from "../../../components/SpinnerOverlay";
import useHttp from "../../../hooks/useHttp";
import AboutUsSection from "./partials/AboutUsSection";
import CarouselSection from "./partials/CarouselSection";
import ClientsSection from "./partials/ClientsSection";
import ContactUsSection from "./partials/ContactUsSection";
import RecentWorksSection from "./partials/RecentWorksSection";
import ServicesSection from "./partials/ServicesSection";
import StaticsSection from "./partials/StaticsSection";
import TestomonialSection from "./partials/TestomonialSection";

const Home = (props) => {
  const [services, setServices] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [works, setWorks] = useState([]);
  const [clients, setClients] = useState([]);

  const { sendRequest, isLoading } = useHttp();

  useEffect(() => {
    const fetch = async () => {
      let response = await sendRequest({
        url: "/",
      });
      if (response) {
        setServices(response.services);
        setTestimonials(response.testimonials);
        setWorks(response.works);
        setClients(response.clients);
      }
    };
    fetch();
  }, []);
  return (
    <>
      {isLoading && <PageSpinner />}
      {!isLoading && (
        <div>
          <CarouselSection />
          <AboutUsSection />
          <ServicesSection services={services} />
          <StaticsSection />
          <RecentWorksSection works={works} />
          <ClientsSection clients={clients} />
          {testimonials?.length > 0 && (
            <TestomonialSection testimonials={testimonials} />
          )}
          <ContactUsSection />
        </div>
      )}
    </>
  );
};

export default Home;
