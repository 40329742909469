import React from "react";

const PageTitle = (props) => {
  return (
    <h1 className={`text-base font-bold  uppercase  ${props.className}`}>
      {props.children}
    </h1>
  );
};
export default PageTitle;
