import { Splide, SplideSlide } from "@splidejs/react-splide";
import ClientCard from "../../../../components/ClientCard";
import MainTitle from "../../../../components/MainTitle";
import Button from "../../../../components/Button";

const ClientsSection = ({ clients }) => {
  return (
    <div className="max-screen-width relative z-10">
      <MainTitle secondary={"Clients"} main="Our Valuable Clients" />
      <div className="w-full pt-8">
        <Splide
          options={{
            type: "loop",
            width: "calc(100vw - 32px)",
            arrows: false,
            pagination: false,
            autoplay: true,
            interval: 1000,

            easing: "cubic-bezier(0.25, 1, 0.5, 1)",
            perPage: 1,
            perMove: 1,
            gap: 32,
            mediaQuery: "min",
            breakpoints: {
              400: {
                perPage: 2,
              },
              600: {
                perPage: 3,
              },
              900: {
                perPage: 5,
              },
            },
          }}
        >
          {clients.map((client) => (
            <SplideSlide className="border rounded " key={client.id}>
              <ClientCard client={client} />
            </SplideSlide>
          ))}
        </Splide>
      </div>
      <div className="flex-center mt-8">
        <Button to="/clients" theme="outline" className="flex items-center">
          View More
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-4 h-4 ml-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
            />
          </svg>
        </Button>
      </div>
    </div>
  );
};

export default ClientsSection;
