const MainTitle = ({ secondary, main, className, secondaryColor }) => {
  return (
    <div className={` 600px:text-[40px] text-[32px] capitalize ${className}`}>
      <p className={`uppercase text-${secondaryColor ?? "primary"} text-15px`}>
        {secondary}
      </p>
      <h1 className="  font-bold">{main}</h1>
      <div className="grid gap-0.5 grid-flow-col items-start w-fit">
        <div
          className={`w-12 h-2 rounded-full bg-${secondaryColor ?? "primary"} `}
        ></div>
        <div
          className={`w-4 h-2 rounded-full bg-${secondaryColor ?? "primary"} `}
        ></div>
        <div
          className={`w-8 h-2 rounded-full bg-${secondaryColor ?? "primary"} `}
        ></div>
      </div>
    </div>
  );
};

export default MainTitle;
