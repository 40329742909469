import React, { useContext } from "react";
import NavItem from "./NavItem";
import NavItemList from "./NavItemList";
import AuthContext from "../../../../store/admin/AuthContext";
import useHttp from "../../../../hooks/useHttp";
import { useNavigate } from "react-router-dom";
import logo from ".././../../../assets/images/logo.png";

const SideDrawer = ({ isCollapse }) => {
  const authContext = useContext(AuthContext);
  const { sendRequest } = useHttp();
  let navigate = useNavigate();
  const onLogoutHandler = async () => {
    let response = await sendRequest({
      url: "/admin/logout",
      method: "POST",
      token: authContext.token,
    });

    console.log(response);
    if (response) {
      authContext.onLogout();
      navigate("/admin/authentication/login");
    }
  };

  return (
    <div className={`side-drawer ${!isCollapse && "side-drawer__expand"} `}>
      <div
        className={`shadow-lg  bg-primary h-full   text-white grid ${
          !isCollapse && "w-[200px]"
        }`}
        style={{ gridTemplateRows: "3.5rem 1fr auto" }}
      >
        <div className={`flex-center border-b border-b-white border-opacity-5`}>
          <h1
            className={`font-bold my-auto text-3xl ${
              isCollapse && "900px:w-16"
            }`}
          >
            <img src={logo} className="w-28" />
          </h1>
        </div>
        <div className="flex-center items-start my-4">
          <NavItemList isCollapse={isCollapse} />
        </div>
        <NavItem
          onClick={onLogoutHandler}
          name="logout"
          isCollapse={isCollapse}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-7 w-7"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="1.3"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
            />
          </svg>
        </NavItem>
      </div>
    </div>
  );
};
export default SideDrawer;
