import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Button";
import Card from "../../../components/Card";
import Input from "../../../components/Input";
import Overlay from "../../../components/Overlay";
import PageTitle from "../../../components/PageTitle";
import Select from "../../../components/Select";
import SelectGroup from "../../../components/SelectGroup";
import Textarea from "../../../components/Textarea";
import useHttp from "../../../hooks/useHttp";
import AuthContext from "../../../store/admin/AuthContext";
import AlertContext from "../../../store/common/AlertContext";
import { imageUrl } from "../../../utils/Statics";

import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/js/plugins.pkgd.min.js";

import FroalaEditorComponent from "react-froala-wysiwyg";
import { log } from "util";
const WorkForm = (props) => {
  let workId = useParams().workId;
  let navigate = useNavigate();

  const selectImage = useRef();
  const [images, setImages] = useState([]);
  const [video, setVideo] = useState(null);
  const [exisitingVideo, setExistingVideo] = useState(null);

  const [currentImg, setCurrentImg] = useState(null);
  const [existingImages, setExistingImages] = useState([]);
  const [deleteImages, setDeleteImages] = useState([]);

  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [completedAt, setComplatedAt] = useState("");
  const [serviceId, setServiceId] = useState("");
  const [description, setDescription] = useState("");

  const [serviceOptions, setServiceOptions] = useState([]);
  const { token } = useContext(AuthContext);
  const { addAlert } = useContext(AlertContext);
  const { error, isLoading, sendRequest, sendFormRequest, validationErros } =
    useHttp();

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    let formData = new FormData();

    formData.append("name", name);
    formData.append("company", company ?? "");
    formData.append("description", description);
    formData.append("service_id", serviceId);
    formData.append("completed_at", completedAt ?? "");
    if (video) {
      formData.append("video", video);
    }
    if (images != null) {
      images.forEach((img) => {
        formData.append("images[]", img.file);
      });
    }

    if (workId) {
      if (exisitingVideo) {
        formData.append("existing_video", exisitingVideo);
      }
      formData.append("_method", "PATCH");
    }
    if (deleteImages != null && workId) {
      deleteImages.forEach((id) => {
        formData.append("deleteImages[]", id);
      });
    }
    if (existingImages != null && workId) {
      existingImages.forEach((img) => {
        formData.append("existingImages[]", img.id);
      });
    }

    let response = await sendFormRequest({
      url: `/admin/works${workId ? "/" + workId : ""}`,
      token: token,
      method: "post",
      body: formData,
    });

    if (response) {
      addAlert(
        "success",
        workId ? "Updated" : "Created",
        `Work Info ${workId ? "updated" : "created"} successfully`
      );
      navigate("/admin/works");
    }
  };

  const onFilesSelectHandler = (e) => {
    const imagesArray = e.target.files;

    setImages((prev) => {
      let newArray = [...prev];
      for (let i = 0; i < imagesArray.length; i++) {
        newArray.push({
          file: imagesArray[i],
          id: Math.random(),
          url: URL.createObjectURL(imagesArray[i]),
        });
      }
      return newArray;
    });
  };

  const deleteImage = (id) => {
    setImages((prev) => prev.filter((img) => img.id != id));
  };

  const deleteExistingImage = (id) => {
    let img = existingImages.find((img) => img.id == id);
    setExistingImages((prev) => prev.filter((img) => img.id != id));
    setDeleteImages((prev) => {
      let newArr = [...prev];
      newArr.push(img.id);
      return newArr;
    });
  };

  useEffect(() => {
    const init = async () => {
      const serviceList = await sendRequest({
        url: "/admin/services?all=true",
        token: token,
      });

      const servOptions = serviceList.map((sev) => {
        return {
          name: sev.name,
          value: sev.id,
        };
      });

      setServiceOptions(servOptions);
    };
    const getProduct = async () => {
      if (workId) {
        let response = await sendRequest({
          url: "/admin/works/" + workId,
          token: token,
        });

        if (response) {
          setName(response.name);
          setCompany(response.company);
          setComplatedAt(response.completed_at);
          setServiceId(response.service_id);
          setDescription(response.description);
          setExistingImages(response.images);
          setExistingVideo(response.video);
        }
      }
    };
    init();
    getProduct();
  }, []);
  return (
    <div className="p-4 900px:p-6">
      <PageTitle className="mt-4 mr-10">
        works {workId ? "Edit" : "Create"}
      </PageTitle>
      <form onSubmit={onSubmitHandler} className="mt-6">
        <Card className="">
          {isLoading && !error && <Overlay />}
          <div className="relative grid gap-6 550px:grid-cols-2">
            <div className="550px:col-span-2">
              <p className="mb-3 font-medium">
                Images{" "}
                <span className="font-normal text-gray-400 text-11px">
                  (Minimum 1)
                </span>
              </p>
              <div
                className={`grid flex-wrap gap-4 p-4 min-h-40 border   rounded-md images_container 550px:flex 550px:items-center justify-center ${
                  validationErros?.errors?.images
                    ? "border-red-500"
                    : "border-gray-200"
                }`}
              >
                {/* Image */}
                {existingImages &&
                  existingImages.map((img) => (
                    <div className="relative flex items-center justify-between p-4 group bg-primary bg-opacity-10 550px:bg-opacity-0 550px:p-0 rounded-15px 550px:mr-2">
                      <img
                        src={imageUrl(img.path)}
                        className="h-16 550px:h-32 rounded-15px"
                      />
                      <div className="transition-all duration-300 550px:opacity-0 flex-center 550px:absolute top-2 right-2 550px:group-hover:opacity-100">
                        <svg
                          onClick={() => deleteExistingImage(img.id)}
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 text-gray-500 cursor-pointer hover:text-current"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </div>
                    </div>
                  ))}

                {images.map((img) => {
                  return (
                    <div className="relative flex items-center justify-between p-4 group bg-primary bg-opacity-10 550px:bg-opacity-0 550px:p-0 rounded-15px 550px:mr-2">
                      <img
                        src={img.url}
                        className="h-16 550px:h-32 rounded-15px"
                      />
                      <div className="transition-all duration-300 550px:opacity-0 flex-center 550px:absolute top-2 right-2 550px:group-hover:opacity-100">
                        <svg
                          onClick={() => deleteImage(img.id)}
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 text-gray-500 cursor-pointer hover:text-current"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </div>
                    </div>
                  );
                })}

                {/* Input Field */}
                <input
                  ref={selectImage}
                  onChange={onFilesSelectHandler}
                  type="file"
                  className="image_input"
                  accept=".gif,.jpg,.jpeg,.png"
                  hidden
                />

                {/* Add Button */}
                <button
                  onClick={() => selectImage.current.click()}
                  type="button"
                  className="w-10 h-10 p-2 m-auto transition-all duration-300 bg-gray-300 rounded-full 550px:mx-0 hover:bg-gray-300 flex-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-8 text-gray-100 "
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 4v16m8-8H4"
                    />
                  </svg>
                </button>
              </div>
              {validationErros?.errors?.images && (
                <p className="mt-1 ml-2 text-xs text-red-500">
                  {validationErros.errors.images}
                </p>
              )}
            </div>

            {workId && exisitingVideo ? (
              <Button
                type="danger"
                className="550px:col-span-2"
                onClick={() => {
                  setExistingVideo(null);
                }}
              >
                Delete Existing Video
              </Button>
            ) : (
              <Input
                model={video}
                className="550px:col-span-2"
                onChange={(e) => setVideo(e.target.files[0])}
                label="Video (Optional)"
                error={validationErros?.errors?.video}
                type="file"
                accept="video"
              />
            )}

            <Select
              model={serviceId}
              set={setServiceId}
              options={serviceOptions}
              label="Service"
              error={validationErros?.errors?.service_id}
            />
            <Input
              model={name}
              set={setName}
              label="Project Name"
              error={validationErros?.errors?.name}
            />
            <Input
              model={company}
              set={setCompany}
              label="Company"
              error={validationErros?.errors?.company}
            />
            <Input
              model={completedAt}
              set={setComplatedAt}
              label="Project Completed On"
              error={validationErros?.errors?.completed_at}
              type="date"
            />

            <div className="550px:col-span-2">
              <label className="block mb-2 font-medium capitalize">
                Description
              </label>
              <div
                className={`${
                  validationErros?.errors?.description &&
                  "border border-red-500 rounded-[10px]"
                }`}
              >
                <FroalaEditorComponent
                  model={description}
                  onModelChange={(value) => {
                    setDescription(value);
                  }}
                  tag="textarea"
                  config={{
                    paragraphDefaultSelection: "Normal",
                    heightMin: 150,
                    fontSizeDefaultSelection: "13",
                    fontFamilySelection: false,
                    fontSizeSelection: false,
                    paragraphFormatSelection: true,
                    quickInsertEnabled: false,
                    toolbarButtons: {
                      moreText: {
                        buttons: [
                          "bold",
                          "italic",
                          "underline",
                          "strikeThrough",
                          "fontSize",
                          "textColor",
                        ],
                        buttonsVisible: 6,
                      },

                      moreParagraph: {
                        buttons: [
                          "alignLeft",
                          "alignCenter",
                          "alignJustify",
                          "alignRight",
                          "formatOL",
                          "formatUL",
                          "outdent",
                          "indent",
                          "quote",
                        ],

                        buttonsVisible: 9,
                      },

                      moreMisc: {
                        buttons: ["undo", "redo"],

                        align: "right",

                        buttonsVisible: 2,
                      },
                    },
                  }}
                />
              </div>
              {validationErros?.errors?.description && (
                <p className="mt-1 text-xs text-red-500">
                  {validationErros?.errors?.description}
                </p>
              )}
            </div>
          </div>
        </Card>
        <div className="mt-6 flex-center">
          <Button isLoading={isLoading} className="">
            {workId ? "Update" : "Create"} Work
          </Button>
        </div>
      </form>
    </div>
  );
};
export default WorkForm;
