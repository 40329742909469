import React, { createContext, Fragment, useEffect, useState } from "react";
import Snackbar from "../../components/Snackbar";
import useHttp from "../../hooks/useHttp";

const AlertContext = createContext({
  alerts: [],
  addAlert: (type, title, messgae) => {},
  removeAlert: (id) => {},
});

export const AlertProvider = (props) => {
  const [alerts, setAlerts] = useState([]);

  const onAddAlert = (type, title, message) => {
    setAlerts((prev) => {
      return [
        ...prev,
        {
          id: Math.floor(Math.random() * 100),
          type,
          title,
          message,
        },
      ];
    });
  };

  setTimeout(() => {}, [500]);

  const onRemoveAlert = (id) => {
    setAlerts((prev) => {
      let newAlertList = [...prev].filter((alert) => {
        return alert.id != id;
      });
      return newAlertList;
    });
  };

  return (
    <AlertContext.Provider
      value={{
        alerts: alerts,
        addAlert: onAddAlert,
      }}
    >
      <div className="fixed z-50 right-4 top-16 grid gap-2  grid-flow-row">
        {alerts.length > 0 &&
          alerts.map((alert) => (
            <Snackbar
              onClose={() => {
                onRemoveAlert(alert.id);
              }}
              key={alert.id}
              type={alert.type}
              message={alert.message}
              title={alert.title}
            />
          ))}
      </div>
      {props.children}
    </AlertContext.Provider>
  );
};
export default AlertContext;
