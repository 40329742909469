import "../assets/css/animations.css";
const PageSpinner = (props) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full z-50 flex-center bg-white ">
      <span class="loader5"></span>
    </div>
  );
};

export default PageSpinner;
