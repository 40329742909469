import React from "react";
import { Link } from "react-router-dom";
import Spinner3 from "./Spinner3";

const Button = ({
  isLoading,
  children,
  className,
  buttonType,
  type = "primary",
  ...props
}) => {
  const theme = props.theme ?? "solid";

  if (props.to) {
    return (
      <Link
        {...props}
        className={`btn-${theme} t-all relative ${
          (type == "primary" || type == "info") && "btn-primary"
        } ${type == "success" && "btn-success"}  ${
          type == "warning" && "btn-warning"
        } ${type == "danger" && "btn-danger"} ${
          type == "secondary" && "btn-secondary"
        } ${type == "light" && "btn-light"}  ${className}`}
      >
        {isLoading && (
          <div
            className={`btn-${theme} absolute  top-0 left-0 h-full w-full flex-center  ${
              (type == "primary" || type == "info") && "btn-primary"
            } ${type == "success" && "btn-success"}  ${
              type == "warning" && "btn-warning"
            } ${type == "danger" && "btn-danger"} ${
              type == "secondary" && "btn-secondary"
            } ${type == "light" && "btn-light"}  ${className}`}
          >
            <Spinner3 />
          </div>
        )}
        {children}
      </Link>
    );
  } else {
    return (
      <button
        type={buttonType ?? "submit"}
        {...props}
        className={`btn-${theme} relative ${
          (type == "primary" || type == "info") && "btn-primary"
        } ${type == "success" && "btn-success"}  ${
          type == "warning" && "btn-warning"
        } ${type == "danger" && "btn-danger"} ${
          type == "secondary" && "btn-secondary"
        }  ${
          type == "light" && "btn-light"
        } ${className} disabled:bg-gray-100 t-all disabled:text-gray-400 disabled:hover:text-gray-400 disabled:hover:bg-gray-100 disabled:0px:shadow-none disabled:drop-shadow-none disabled:cursor-not-allowed`}
      >
        {isLoading && (
          <div
            className={`btn-${theme} absolute  top-0 left-0 h-full w-full flex-center  ${
              (type == "primary" || type == "info") && "btn-primary"
            } ${type == "success" && "btn-success"}  ${
              type == "warning" && "btn-warning"
            } ${type == "danger" && "btn-danger"} ${
              type == "secondary" && "btn-secondary"
            } ${type == "light" && "btn-light"} ${className}`}
          >
            <Spinner3 />
          </div>
        )}
        {children}
      </button>
    );
  }
};
export default Button;
