import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Button";
import Card from "../../../components/Card";
import Input from "../../../components/Input";
import Overlay from "../../../components/Overlay";
import PageTitle from "../../../components/PageTitle";
import Select from "../../../components/Select";
import SelectGroup from "../../../components/SelectGroup";
import Textarea from "../../../components/Textarea";
import useHttp from "../../../hooks/useHttp";
import AuthContext from "../../../store/admin/AuthContext";
import AlertContext from "../../../store/common/AlertContext";
import { imageUrl } from "../../../utils/Statics";

import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/js/plugins.pkgd.min.js";

import FroalaEditorComponent from "react-froala-wysiwyg";
import { log } from "util";
const CareerForm = (props) => {
  let careerId = useParams().careerId;
  let navigate = useNavigate();

  const [name, setName] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [existingAttachments, setExistingAttachments] = useState(null);
  const [description, setDescription] = useState("");

  const { token } = useContext(AuthContext);
  const { addAlert } = useContext(AlertContext);
  const { error, isLoading, sendFormRequest, sendRequest, validationErros } =
    useHttp();

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);

    if (existingAttachments) {
      formData.append("existing_attachments", existingAttachments);
    }

    for (let i = 0; i < attachments.length; i++) {
      formData.append("attachments[]", attachments.item(i));
    }

    if (careerId) {
      formData.append("_method", "PATCH");
    }
    let response = await sendFormRequest({
      url: `/admin/careers${careerId ? "/" + careerId : ""}`,
      token: token,
      body: formData,
    });

    if (response) {
      addAlert(
        "success",
        careerId ? "Updated" : "Created",
        `Career Info ${careerId ? "updated" : "created"} successfully`
      );
      navigate("/admin/careers");
    }
  };

  useEffect(() => {
    const getCareer = async () => {
      if (careerId) {
        let response = await sendRequest({
          url: "/admin/careers/" + careerId,
          token: token,
        });

        if (response) {
          setName(response.name);
          setDescription(response.description);
          setExistingAttachments(response.attachments);
        }
      }
    };

    getCareer();
  }, []);

  return (
    <div className="p-4 900px:p-6">
      <PageTitle className="mt-4 mr-10">
        careers {careerId ? "Edit" : "Create"}
      </PageTitle>

      <form onSubmit={onSubmitHandler} className="mt-6">
        <Card className="">
          {isLoading && !error && <Overlay />}
          <div className="relative grid gap-6 ">
            <Input
              model={name}
              set={setName}
              label="Vacancy Name"
              error={validationErros?.errors?.name}
            />

            <div>
              <label className="block mb-2 font-medium capitalize">
                Description
              </label>
              <div
                className={`${
                  validationErros?.errors?.description &&
                  "border border-red-500 rounded-[10px]"
                }`}
              >
                <FroalaEditorComponent
                  model={description}
                  onModelChange={(value) => {
                    setDescription(value);
                  }}
                  tag="textarea"
                  config={{
                    htmlAllowedAttrs: [],
                    paragraphDefaultSelection: "Normal",
                    heightMin: 150,
                    fontSizeDefaultSelection: "13",
                    fontFamilySelection: false,
                    fontSizeSelection: false,
                    paragraphFormatSelection: true,
                    quickInsertEnabled: false,
                    toolbarButtons: {
                      moreText: {
                        buttons: [
                          "bold",
                          "italic",
                          "underline",
                          "strikeThrough",
                          "fontSize",
                          "textColor",
                        ],
                        buttonsVisible: 6,
                      },

                      moreParagraph: {
                        buttons: [
                          "alignLeft",
                          "alignCenter",
                          "alignJustify",
                          "alignRight",
                          "formatOL",
                          "formatUL",
                          "outdent",
                          "indent",
                          "quote",
                        ],

                        buttonsVisible: 9,
                      },

                      moreMisc: {
                        buttons: ["undo", "redo"],

                        align: "right",

                        buttonsVisible: 2,
                      },
                    },
                  }}
                />
              </div>
              {validationErros?.errors?.description && (
                <p className="mt-1 text-xs text-red-500">
                  {validationErros?.errors?.description}
                </p>
              )}
            </div>

            {existingAttachments?.length > 0 ? (
              <Button
                buttonType={"button"}
                type="danger"
                onClick={() => setExistingAttachments(null)}
              >
                Re-add Attachments
              </Button>
            ) : (
              <Input
                model={attachments}
                onChange={(e) => {
                  setAttachments(e.target.files);
                }}
                label="Attachments (Optional)"
                error={validationErros?.errors?.attachments}
                type="file"
                multiple
              />
            )}
          </div>
        </Card>
        <div className="mt-6 flex-center">
          <Button isLoading={isLoading} className="">
            {careerId ? "Update" : "Create"} Career
          </Button>
        </div>
      </form>
    </div>
  );
};
export default CareerForm;
