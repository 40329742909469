import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import Card from "../../../components/Card";
import PageSpinner from "../../../components/PageSpinner";
import SecondaryTitle from "../../../components/SecondaryTitle";
import SpinnerOverlay from "../../../components/SpinnerOverlay";

import useHttp from "../../../hooks/useHttp";
import { imageUrl } from "../../../utils/Statics";

const Career = (props) => {
  const [career, setCareer] = useState(null);
  const { error, isLoading, sendRequest } = useHttp();
  let careerId = useParams().careerId;
  const fetch = async () => {
    const response = await sendRequest({
      url: "/careers/" + careerId,
    });

    if (response != null) {
      setCareer(response);
    }
  };
  useEffect(() => {
    fetch();
  }, []);
  return (
    <div className="">
      <Breadcrumb
        main={career?.name}
        mainClassName="600px:text-[32px] text-[28px]"
        secondary={"Career"}
      />
      <div className="relative my-8 max-screen-width min-h-32">
        {isLoading && <SpinnerOverlay />}
        {career && (
          <>
            <div
              dangerouslySetInnerHTML={{
                __html: career.description.replace(
                  `<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>`,
                  ""
                ),
              }}
              className="mt-6 html_editor 650px:mt-8"
            ></div>
            {career?.attachments.length > 0 && (
              <Card className="mt-6">
                <SecondaryTitle title={"Attachments"} />
                <div className="grid gap-3 mt-4">
                  {career.attachments.map((attachment, index) => (
                    <a
                      target={"_blank"}
                      className="flex items-center text-gray-500 hover:text-primary hover:underline t-all"
                      href={imageUrl(attachment.path)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 mr-2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                        />
                      </svg>

                      {attachment.path
                        .split("/")
                        .slice(-1)[0]
                        .split(".")[0]
                        .substr(0, 20) +
                        "." +
                        attachment.path.split("/").slice(-1)[0].split(".")[1]}
                    </a>
                  ))}
                </div>
              </Card>
            )}
            <Card className="bg-gray-100  text-center text-[18px] mt-8 ">
              Send your resume and cover letter to{" "}
              <span className="font-semibold">info@softmastergroup.com</span>
            </Card>
          </>
        )}
      </div>
    </div>
  );
};

export default Career;
