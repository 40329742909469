import Pagination from "../../../components/Pagination";
import ProjectCard from "../../../components/ProjectCard";
import EmptyRow from "../../../components/table/EmptyRow";
import useHttp from "../../../hooks/useHttp";
import { removeQueryParam } from "../../../utils/Statics";
import src from "../../../assets/images/no-results.png";
import MainTitle from "../../../components/MainTitle";
import CareerCard from "../../../components/CareerCard";
import { useEffect, useState } from "react";
import Breadcrumb from "../../../components/Breadcrumb";
import PageSpinner from "../../../components/PageSpinner";
import SpinnerOverlay from "../../../components/SpinnerOverlay";
const Careers = (props) => {
  const [careers, setCareers] = useState([]);
  const { error, isLoading, sendRequest } = useHttp();

  const fetch = async () => {
    const response = await sendRequest({
      url: "/careers/",
    });

    if (response != null) {
      setCareers(response);
    }
  };
  useEffect(() => {
    fetch();
  }, []);
  return (
    <>
      <div className="">
        <Breadcrumb main={"Vacancies"} secondary={"Join with us"} />
        <div className="relative my-8 max-screen-width min-h-32">
          {isLoading && <SpinnerOverlay />}
          {careers.length == 0 && !isLoading && (
            <div className="mt-12 flex-center">
              <img src={src} className="h-20" />
              <h1 className="mt-4 mb-1 text-base font-semibold text-gray-900 ">
                Sorry No Vacancy Found :(
              </h1>
              <p className="text-gray-400">
                We're sorry, we have no vacancy for now
              </p>
            </div>
          )}
          <div className="grid gap-8 my-8 500px:grid-cols-2 950px:grid-cols-3 1200px:grid-cols-3 ">
            {careers?.map((career) => (
              <CareerCard career={career} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Careers;
