import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./assets/css/spinner.css";
import "./index.css";
import "./assets/css/animations.css";

import App from "./App";
import { AuthProvider } from "./store/admin/AuthContext";
import { AlertProvider } from "./store/common/AlertContext";

ReactDOM.render(
  <BrowserRouter>
    <AlertProvider>
      <App />
    </AlertProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
