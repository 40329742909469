import React, { useEffect, useState } from "react";
import Card from "./Card";
import Input from "./Input";
import useUpdate from "../hooks/useUpdate";

import Select from "./Select";

const SearchInput = (props) => {
  const [column, setColumn] = useState();
  const [value, setValue] = useState("");
  let searchParams = props.getSearchParams();
  useUpdate(() => {
    props.setSearchParams({
      ...searchParams,
      column: column ?? "",
      value: value ?? "",
      page: 1,
    });
  }, [column, value]);

  useEffect(() => {
    setValue(searchParams.value ?? "");
    setColumn(searchParams.column ?? props.options[0].split(":")[0]);
  }, []);
  return (
    <Card className={`${props.className}`}>
      <form
        className={`550px:grid gap-6 grid-flow-col `}
        style={{ gridTemplateColumns: "1fr auto" }}
      >
        <div className="relative">
          <Input
            inputClassName="pl-11"
            placeholder={`Search by column`}
            model={value}
            set={setValue}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 absolute left-3 text-gray-400 top-2 "
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </div>
        <select
          value={column}
          onChange={(e) => setColumn(e.target.value)}
          className={`px-3 py-2 w-full rounded-md border mt-4 550px:mt-auto  550px:w-40 800px:w-52 bg-darkBlue text-darkBlue font-bold capitalize outline-none bg-opacity-10 `}
        >
          {props.options != null &&
            props.options.map((opt) => {
              let optArr = opt.split(":");
              return (
                <option
                  className="capitalize"
                  key={optArr[0]}
                  value={optArr[0]}
                >
                  {optArr[1]}
                </option>
              );
            })}
        </select>
      </form>
    </Card>
  );
};
export default SearchInput;
