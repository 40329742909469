import React from "react";
import { NavLink } from "react-router-dom";
const NavItem = (props) => {
  if (props.to) {
    return (
      <NavLink
        end
        to={props.to}
        className={(data) => {
          let classes = `py-2  border-l-4 group border-r-4 relative hover:bg-white hover:bg-opacity-5 border-transparent w-full t-all capitalize flex items-center ${
            !props.isCollapse && "pl-3"
          }`;

          return classes.concat(
            " ",
            data.isActive
              ? "text-white border-l-white bg-white bg-opacity-5"
              : "text-white text-opacity-60 hover:text-white "
          );
        }}
      >
        <div className={`${props.isCollapse && "mx-auto "}`}>
          {props.children}
        </div>
        <div
          className={`font-medium  whitespace-nowrap ${
            props.isCollapse
              ? "h-10  hidden group-hover:flex t-all capitalize items-center left-[72px] top-0 border-0 rounded-none  gap-1 bg-primary overflow-hidden p-0 shadow-none absolute w-44"
              : ""
          }`}
        >
          <p
            className={`${
              props.isCollapse &&
              " bg-white bg-opacity-5 py-2 leading-6 h-full w-full font-normal text-white "
            } pl-2`}
          >
            {props.name}
          </p>
        </div>
      </NavLink>
    );
  } else {
    return (
      <button
        onClick={props.onClick}
        className={`py-2 pl-3 border-l-4 border-r-4 border-transparent w-full hover:bg-white hover:bg-opacity-5  t-all capitalize flex items-center text-gray-200 hover:text-white`}
      >
        <div className={`${props.isCollapse && "mx-auto mr-[16px]"} `}>
          {props.children}
        </div>
        <p className={`ml-2 font-medium ${props.isCollapse && "900px:hidden"}`}>
          {props.name}
        </p>
      </button>
    );
  }
};
export default NavItem;
