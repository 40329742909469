import React, { useState } from "react";
import "../../assets/css/admin-layout-collapse.css";
import SideDrawer from "./partials/SideDrawer/SideDrawer";
import { CSSTransition } from "react-transition-group";
import Backdrop from "../../components/Backdrop";
import { Outlet } from "react-router-dom";
import Toolbar from "./partials/Toolbar";
const AdminLayout = (props) => {
  const [collapse, setCollapse] = useState(true);
  const [showDrawer, setShowDrawer] = useState(false);

  return (
    <div className="main-layout h-full w-full ">
      <Backdrop
        className={`${collapse ? "hide_backdrop" : "900px:hidden"}`}
        onClick={() => {
          setCollapse((prev) => !prev);
          setShowDrawer((prev) => !prev);
        }}
      />

      <SideDrawer isCollapse={collapse} showDrawer={showDrawer} />

      <div className="main-section ">
        <Toolbar
          onMenuClick={() => {
            setCollapse((prev) => !prev);
            setShowDrawer((prev) => !prev);
          }}
        />
        <div className="main-section__content">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
export default AdminLayout;
