import ToolbarUpper from "./ToolbarUpper";

import ToolbarLower from "./ToolbarLower";
import { useEffect, useState } from "react";
import { Collapse } from "@mui/material";
import ToolbarDrawer from "./ToolbarDrawer";
import { useLocation } from "react-router-dom";
const Toolbar = ({ services }) => {
  const location = useLocation();
  const [showDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    setShowDrawer(false);
  }, [location]);
  return (
    <div>
      <ToolbarLower
        services={services}
        setShowDrawer={setShowDrawer}
        showDrawer={showDrawer}
      />

      <ToolbarDrawer
        services={services}
        setShowDrawer={setShowDrawer}
        showDrawer={showDrawer}
      />
    </div>
  );
};

export default Toolbar;
