import { Link } from "react-router-dom";

const CareerCard = ({ career }) => {
  return (
    <Link
      to={"/careers/" + career.id}
      className="p-6 overflow-hidden group hover:shadow-2xl rounded transform transition-all cursor-pointer duration-300 bg-gray-100 hover:bg-primary hover:text-white flex flex-col justify-between"
    >
      <div>
        <div>
          <h1 className="text-[24px] capitalize font-bold ">{career.name}</h1>
          <div className="grid gap-0.5 grid-flow-col items-start w-fit">
            <div className="w-8 h-1 rounded-full bg-primary group-hover:bg-white "></div>
            <div className="w-2 h-1 rounded-full bg-primary group-hover:bg-white "></div>
            <div className="w-4 h-1 rounded-full bg-primary group-hover:bg-white "></div>
          </div>
        </div>
        <p className="leading-7 text-gray-500 group-hover:text-gray-100 mt-4 text-sm">
          Location: Kandy
          <br />
          Posted On: {career.created_at}
        </p>
      </div>
      <div className="flex items-center relative w-full mt-4">
        <p className=" text-gray-100 group-hover:text-white text-15px   overflow-hidden whitespace-nowrap">
          Apply Now
        </p>
        <div className="w-full absolute top-0 left-0 h-full flex items-center t-all  group-hover:translate-x-28">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-8 text-white group-hover:ml-2 h-8 p-2 rounded-full  bg-primary group-hover:bg-white group-hover:text-primary"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
            />
          </svg>
        </div>
      </div>
    </Link>
  );
};

export default CareerCard;
