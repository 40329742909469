import Button from "../../../../components/Button";
import src from "../../../../assets/images/pattern-1.png";
import src1 from "../../../../assets/images/pattern-2.png";
import logo from "../../../../assets/images/logo.png";
import isoimg from "../../../../assets/images/ISO.jpg";
import isoimg2 from "../../../../assets/images/iso9001.jpg";
import isoimg4 from "../../../../assets/images/ISO4.jpg";

import Fade from "react-reveal/Fade";
import { illustration1 } from "../../../../assets/svg/illustration1";
const CarouselSection = (props) => {
  return (
    <div className="relative">
      {/* <div className="bg-primary top-0 left-[50vw] rotate-[34deg] w-[120vw] h-[200vh]  absolute "></div> */}
      <img
        src={src}
        className="absolute top-0 right-0 hidden w-9/12 900px:block "
      />
      <img
        src={src1}
        className="absolute -bottom-[120px] opacity-20 w-[15%] left-0  hidden 900px:block  "
      />
      <div className="900px:h-[130vh] max-h-[1100px]  relative   pt-[76px] 900px:pt-[82px]">
        {/* <div className="bg-blue-400 -rotate-[55deg] w-[1100px] h-[1600px] absolute bottom-[20%] -right-[100px]"></div> */}
        <Fade left>
          <div className="mb-6 max-screen-width 900px:mb-0">
            <img
              src={logo}
              className="mr-auto w-52 "
              //  style={{ display: "inline-block" }}
            />
            {/* <img src={isoimg4} className="mr-auto w-52 " /> */}
          </div>
        </Fade>
        <div className="grid w-full h-full grid-cols-12 gap-8 900px:-mt-28 900px:text-left max-screen-width">
          <Fade left>
            <div className="h-full col-span-12 900px:col-span-6 flex-center">
              <h1 className="text-[40px] mb-2 font-bold relative w-full ">
                {/* <div className="absolute top-0 left-0 w-6 h-full bg-opacity-100 bg-primary "></div> */}
                <span className="relative z-10 text-primary ">
                  Software Solution For Business{" "}
                </span>
              </h1>
              <p className="mb-6 leading-7 text-gray-500 text-15px">
                Two decade old trusted partner in information technology with
                providing high quality and sophisticated software solution in
                Sri Lankan market place
              </p>
              <div className="grid gap-6 400px:grid-flow-col 900px:mr-auto">
                <Button className={" w-40 py-3"} to="/contactus">
                  Get Started
                </Button>
                <Button to="/aboutus" className={" w-40 py-3"} theme="outline">
                  About Us
                </Button>
              </div>
              <div className="grid gap-2 400px:grid-flow-col 900px:mr-auto">
                <p
                  className=" mt-8 leading-7 text-15px"
                  style={{ textAlign: "justify" }}
                >
                  Softmaster is an ISO 9001 certified software company, which
                  means it maintains industry-standard quality to develop and
                  maintain projects, as well as the entire company's operational
                  processes.
                </p>

                <img
                  src={isoimg2}
                  className="ml-auto w-52 mt-6"
                  //  style={{ display: "inline-block" }}
                />
              </div>
            </div>
          </Fade>
          <Fade right>
            <div
              className="hidden col-span-12 ml-auto 900px:block 900px:col-span-6 "
              dangerouslySetInnerHTML={{ __html: illustration1 }}
            >
              {/* <img src={illustartion} /> */}
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default CarouselSection;
