import React, { useContext, useEffect, useRef, useState } from "react";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import Modal from "../../../../components/Modal";
import Select from "../../../../components/Select";
import Overlay from "../../../../components/Overlay";

import useHttp from "../../../../hooks/useHttp";
import AuthContext from "../../../../store/admin/AuthContext";
import { imageUrl } from "../../../../utils/Statics";
import Textarea from "../../../../components/Textarea";

const ClientModal = (props) => {
  const [existingLogo, setExistingLogo] = useState(null);
  const [logo, setLogo] = useState(null);
  const logoRef = useRef();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const { error, isLoading, sendFormRequest, validationErros } = useHttp();
  const authContext = useContext(AuthContext);

  const onSubmit = async (event) => {
    event.preventDefault();
    let formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    if (logo) {
      formData.append("logo", logo.file);
    }
    if (props.client) {
      formData.append("_method", "PATCH");
    }

    const response = await sendFormRequest({
      url: props.client
        ? "/admin/clients/" + props.client.id
        : "/admin/clients",
      body: formData,
      token: authContext.token,
    });

    if (response != null) {
      props.addAlert(
        "success",
        props.client ? "Updated" : "Created",
        `Client ${props.client ? "Updated" : "Created"} Successfully`
      );
      await props.onComplete();
      resetValues();
    }
  };

  const resetValues = () => {
    setName("");
    setDescription("");
    setLogo(null);
    setExistingLogo(null);
  };

  const setValues = (client) => {
    setDescription(client.description);
    setName(client.name);
    setExistingLogo(client.logo);
  };

  const onLogoSelect = (e) => {
    const logosArray = e.target.files;
    setExistingLogo(null);
    setLogo({
      file: logosArray[0],
      id: Math.random(),
      url: URL.createObjectURL(logosArray[0]),
    });
  };

  useEffect(() => {
    const getClient = () => {
      if (props.client != null) {
        setValues(props.client);
      }
    };
    getClient();
  }, [props.client]);
  return (
    <Modal
      show={props.show}
      title={`${props.client ? "Update" : "Create"} Client`}
      onClose={() => {
        resetValues();
        props.onClose();
      }}
    >
      <form onSubmit={onSubmit} className="">
        <div className="relative grid gap-4 ">
          {isLoading && !error && <Overlay />}
          <div className="flex-center">
            <input ref={logoRef} onChange={onLogoSelect} hidden type={"file"} />
            <div
              onClick={() => {
                logoRef.current.click();
              }}
              className={`bg-gray-200 t-all rounded-sm shadow-md w-72 h-40 flex-center ${
                validationErros?.errors?.logo && "border-red-500 border-1"
              }`}
            >
              {existingLogo && (
                <img
                  src={imageUrl(existingLogo.path)}
                  className="w-full h-full object-cover"
                />
              )}
              {logo && (
                <img src={logo.url} className="w-full h-full object-cover" />
              )}
              {!logo && !existingLogo && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1}
                  stroke="currentColor"
                  className="w-14 h-14"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
              )}
            </div>
            <p className="text-center mt-2"> Select Company Logo</p>
          </div>
          <Input
            label="Name"
            set={setName}
            model={name}
            error={validationErros?.errors?.name ?? null}
          />

          <Textarea
            label="Description"
            set={setDescription}
            model={description}
            error={validationErros?.errors?.description ?? null}
          />
        </div>
        <div className="flex-center 600px:col-span-2 mt-4">
          <Button isLoading={isLoading}>
            {props.client ? "Update" : "Create"} Client
          </Button>
        </div>
      </form>
    </Modal>
  );
};
export default ClientModal;
