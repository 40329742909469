import MainTitle from "../../../../components/MainTitle";
import illustartion from "../../../../assets/images/illustration-2.png";
import Button from "../../../../components/Button";
import Fade from "react-reveal/Fade";
const ContactUsSection = (props) => {
  return (
    <div className="my-8 max-screen-width grid 900px:grid-cols-2">
      <Fade left>
        <div className="h-fit my-auto">
          <MainTitle
            secondary={"Contact us"}
            main="We like to start your next project with us"
          />
          {/* <p className="my-6 text-gray-500 text-15px leading-7">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna.
          </p> */}
          <Button to="/contactus" className="uppercase mt-6 inline-block py-3">
            Contact us
          </Button>
        </div>
      </Fade>

      <Fade right>
        <div className="flex-center hidden 900px:flex">
          <img src={illustartion} className="w-9/12  rounded-full " />
        </div>
      </Fade>
    </div>
  );
};

export default ContactUsSection;
