import location from "../assets/images/location.png";
const Map = ({ className }) => {
  return (
    <div className={`${className} overflow-hidden rounded shadow relative`}>
      <img
        src={location}
        className={`w-full h-full scale-150 bg-white object-cover object-center  `}
      />
      <a
        className=""
        target={"_blank"}
        href="https://www.google.com/maps/place/Soft-Master+Technologies+(Pvt)+Ltd/@7.28766,80.6256875,18z/data=!4m5!3m4!1s0x3ae36895e03b64cd:0x984bfceee7505816!8m2!3d7.2876706!4d80.626519"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-8 h-8 p-1.5 t-all hover:bg-gray-100 text-gray-900  rounded bg-white shadow-lg absolute bottom-4 right-4 "
        >
          <path
            fillRule="evenodd"
            d="M15 3.75a.75.75 0 01.75-.75h4.5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0V5.56l-3.97 3.97a.75.75 0 11-1.06-1.06l3.97-3.97h-2.69a.75.75 0 01-.75-.75zm-12 0A.75.75 0 013.75 3h4.5a.75.75 0 010 1.5H5.56l3.97 3.97a.75.75 0 01-1.06 1.06L4.5 5.56v2.69a.75.75 0 01-1.5 0v-4.5zm11.47 11.78a.75.75 0 111.06-1.06l3.97 3.97v-2.69a.75.75 0 011.5 0v4.5a.75.75 0 01-.75.75h-4.5a.75.75 0 010-1.5h2.69l-3.97-3.97zm-4.94-1.06a.75.75 0 010 1.06L5.56 19.5h2.69a.75.75 0 010 1.5h-4.5a.75.75 0 01-.75-.75v-4.5a.75.75 0 011.5 0v2.69l3.97-3.97a.75.75 0 011.06 0z"
            clipRule="evenodd"
          />
        </svg>
      </a>
    </div>
  );
};

export default Map;
