import MainTitle from "./MainTitle";

const Breadcrumb = ({ secondary, main, mainClassName }) => {
  return (
    <div className="relative">
      <div className="bg-primary  mt-[72px] 850px:mt-[88px] h-[20vh] max-h-[350px] relative overflow-hidden flex items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          width="100%"
          height="100%"
          preserveAspectRatio="none"
          viewBox="0 0 1440 560"
          className="absolute top-0 left-0"
        >
          <g mask='url("#SvgjsMask1009")' fill="none">
            <path
              d="M1440 0L1178.53 0L1440 136.77z"
              fill="rgba(255, 255, 255, .1)"
            ></path>
            <path
              d="M1178.53 0L1440 136.77L1440 139.8L855.8699999999999 0z"
              fill="rgba(255, 255, 255, .075)"
            ></path>
            <path
              d="M855.8699999999999 0L1440 139.8L1440 334.78L681.0299999999999 0z"
              fill="rgba(255, 255, 255, .05)"
            ></path>
            <path
              d="M681.0299999999999 0L1440 334.78L1440 452.08L646.7099999999998 0z"
              fill="rgba(255, 255, 255, .025)"
            ></path>
            <path
              d="M0 560L158.84 560L0 394.08000000000004z"
              fill="rgba(0, 0, 0, .1)"
            ></path>
            <path
              d="M0 394.08000000000004L158.84 560L796.89 560L0 322.58000000000004z"
              fill="rgba(0, 0, 0, .075)"
            ></path>
            <path
              d="M0 322.58000000000004L796.89 560L1051.88 560L0 291.56000000000006z"
              fill="rgba(0, 0, 0, .05)"
            ></path>
            <path
              d="M0 291.56L1051.88 560L1076.47 560L0 283.61z"
              fill="rgba(0, 0, 0, .025)"
            ></path>
          </g>
          <defs>
            <mask id="SvgjsMask1009">
              <rect width="1440" height="560" fill="#ffffff"></rect>
            </mask>
          </defs>
        </svg>
        <div className="grid w-full text-white  900px:text-left max-screen-width">
          <div className="">
            <div>
              <p className="uppercase text-third text-15px">{secondary}</p>
              <h1
                className={` capitalize font-bold ${
                  mainClassName ?? "600px:text-[40px] text-[32px]"
                }`}
              >
                {main}
              </h1>
              <div className="grid gap-0.5 grid-flow-col items-start w-fit">
                <div className="w-12 h-2 rounded-full bg-third "></div>
                <div className="w-4 h-2 rounded-full bg-third "></div>
                <div className="w-8 h-2 rounded-full bg-third "></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="100%"
        className="absolute bottom-0 left-0 h-12"
        preserveAspectRatio="none"
        viewBox="0 0 1440 120"
      >
        <g mask='url("#SvgjsMask1011")' fill="none">
          <path
            d="M 0,15 C 96,21.6 288,47.6 480,48 C 672,48.4 768,18 960,17 C 1152,16 1344,37.8 1440,43L1440 120L0 120z"
            fill="rgba(217, 220, 222, 0.17)"
          ></path>
          <path
            d="M 0,119 C 144,108.6 432,67.2 720,67 C 1008,66.8 1296,107.8 1440,118L1440 120L0 120z"
            fill="rgba(255, 255, 255, 1)"
          ></path>
        </g>
        <defs>
          <mask id="SvgjsMask1011">
            <rect width="1440" height="120" fill="#ffffff"></rect>
          </mask>
        </defs>
      </svg> */}
    </div>
  );
};

export default Breadcrumb;
