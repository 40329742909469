import MainTitle from "../../../../components/MainTitle";
import illustartion from "../../../../assets/images/illustration-5.png";
import Strategy from "./Strategy";
const StrategySection = (props) => {
  return (
    <div className="my-8 max-screen-width">
      <div className=" mb-8">
        <div>
          <MainTitle secondary={"Strategies"} main="What is so greate" />
          <p className="my-6 text-gray-500 text-15px leading-7">
            We try to do things best for the value you pay. With more than ten
            years of experianse and our talanted staff we can provide you the
            best solutions you can have.
          </p>
        </div>
      </div>
      <div className="grid gap-6 900px:grid-cols-2">
        <div className="hidden 900px:flex flex-center">
          <img src={illustartion} />
        </div>

        <div className="grid  gap-6">
          <Strategy text="Guarantees high quality of our deliverables." />
          <Strategy text="Assure a steady quality by guarantee high quality of our deliverables." />
          <Strategy text="Understand In-depth clients' business requirements." />
          <Strategy text="Deliver world-class End-to-End IT solutions." />
          <Strategy text="Implement cutting-edge innovative solutions." />
        </div>
      </div>
      <div className="grid gap-6 mt-6">
        <Strategy text="Deliver secure, reliable and scalable applications that help businesses excel in today's rapidly evolving economy." />
        <Strategy text="Gain client's satisfaction by fully understand and address our client’s requirements" />
        <Strategy text="Our software development process is continuously monitored and improved to maximize productivity." />
        <Strategy text="Seeking Continuous improvement. Adopting International & best practice standards." />
      </div>
    </div>
  );
};

export default StrategySection;
