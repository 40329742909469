import { useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import Pagination from "../../../components/Pagination";
import ProjectCard from "../../../components/ProjectCard";
import EmptyRow from "../../../components/table/EmptyRow";
import useHttp from "../../../hooks/useHttp";
import { removeQueryParam } from "../../../utils/Statics";
import src from "../../../assets/images/no-results.png";
import MainTitle from "../../../components/MainTitle";
import PageSpinner from "../../../components/PageSpinner";
import SpinnerOveraly from "../../../components/SpinnerOverlay";

const Service = (props) => {
  const serviceId = useParams().serviceId;
  const [service, setService] = useState(null);
  const [works, setWorks] = useState([]);
  const [paginationData, setPaginationData] = useState(null);
  const { error, isLoading, sendRequest } = useHttp();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const fetch = async () => {
    const response = await sendRequest({
      url: "/services/" + serviceId,
      queryParams: {
        page: searchParams.get("page") ?? 1,
      },
    });

    if (response != null) {
      setService(response.service);
      setWorks(response.works.data?.length > 0 ? response.works.data : []);
      delete response.works.data;
      setPaginationData(response.works.last_page != 1 ? response.works : null);
    }
  };
  useEffect(() => {
    fetch();
  }, [searchParams, location]);
  return (
    <>
      <div className="">
        <Breadcrumb main={service?.name} secondary={"Services"} />
        <div className="relative mt-6 mb-12 max-screen-width">
          {isLoading && <SpinnerOveraly />}
          <p className="mb-6 font-medium leading-6 text-gray-500 ">
            {service?.description}
          </p>
          {works.length == 0 && !isLoading && (
            <div className="mt-12 flex-center">
              <img src={src} className="h-20" />
              <h1 className="mt-4 mb-1 text-base font-semibold text-gray-900 ">
                Sorry No Results Found :(
              </h1>
              <p className="text-gray-400">
                We're sorry, work your looking for is not found
              </p>
            </div>
          )}
          <div className="grid gap-8 my-8 500px:grid-cols-2 950px:grid-cols-3 1200px:grid-cols-4 ">
            {works?.map((work) => (
              <ProjectCard work={work} />
            ))}
          </div>
          {paginationData && (
            <Pagination
              currentPage={searchParams.get("page") ?? 1}
              setCurrentPage={(page) =>
                setSearchParams({
                  ...getSearchParams(),
                  page: page,
                })
              }
              data={paginationData}
              path={location.pathname}
              searchParams={removeQueryParam("page", location.search)}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Service;
