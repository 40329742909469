import React from "react";

const TableCol = (props) => {
  return (
    <td
      {...props}
      className={` border-b-gray-200 px-4 py-1.5 text-gray-500   ${props.className}`}
    >
      {props.children}
    </td>
  );
};
export default TableCol;
