import MainTitle from "../../../../components/MainTitle";
import TestomonialCard from "../../../../components/TestomonialCard";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import ProjectCard from "../../../../components/ProjectCard";
import Button from "../../../../components/Button";
const RecentWorksSection = ({ works }) => {
  return (
    <div className="relative z-10 my-8 max-screen-width">
      <MainTitle secondary={"projects"} main="our main products" />
      <div className="w-full pt-8">
        <Splide
          options={{
            type: "loop",
            width: "calc(100vw - 32px)",
            arrows: false,
            pagination: false,
            autoplay: true,
            interval: 3000,
            easing: "cubic-bezier(0.25, 1, 0.5, 1)",
            perPage: 1,
            perMove: 1,
            gap: 32,
            mediaQuery: "min",
            breakpoints: {
              550: {
                perPage: 2,
              },
              1200: {
                perPage: 4,
                gap: 24,
              },
              950: {
                perPage: 3,
              },
            },
          }}
        >
          {works.map((work) => (
            <SplideSlide key={works.id}>
              <ProjectCard work={work} />
            </SplideSlide>
          ))}
        </Splide>
      </div>
      {/* <div className="mt-8 flex-center">
        <Button className={"py-3 uppercase"}>Explore More</Button>
      </div> */}
    </div>
  );
};

export default RecentWorksSection;
