import React, { useEffect, useState, useContext } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import Button from "../../../components/Button";
import PageTitle from "../../../components/PageTitle";
import useHttp from "../../../hooks/useHttp";
import AuthContext from "../../../store/admin/AuthContext";
import AlertContext from "../../../store/common/AlertContext";
import SearchInput from "../../../components/SearchInput";
import PageTable from "../../../components/PageTable";

import MessageModal from "./partials/MessageModal";
import AddButton from "../../../components/AddButton";
import Card from "../../../components/Card";
import Pagination from "../../../components/Pagination";
import {
  imageUrl,
  removeQueryParam,
  searchParamsToObject,
} from "../../../utils/Statics";
import ConfirmationModal from "../../../components/ConfirmationModal";

const InboxPendingIndex = (props) => {
  const [selected, setSelected] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [paginationData, setPaginationData] = useState(null);
  const [messages, setMessages] = useState([]);

  const { error, isLoading, sendRequest } = useHttp();
  const { token } = useContext(AuthContext);
  const { addAlert } = useContext(AlertContext);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const fetch = async () => {
    const response = await sendRequest({
      url: "/admin/messages/pending",
      token: token,
      queryParams: {
        page: searchParams.get("page") ?? 1,
        column: searchParams.get("column") ?? "",
        value: searchParams.get("value") ?? "",
      },
    });

    if (response != null) {
      setSelected(null);
      setMessages(response.data?.length > 0 ? response.data : []);
      delete response.data;
      setPaginationData(response.last_page != 1 ? response : null);
    }
  };
  const deleteMessage = async () => {
    setDeleteId(null);
    const response = await sendRequest({
      url: "/admin/messages/" + deleteId,
      token: token,
      method: "delete",
    });

    if (response != null) {
      fetch();
    }
  };
  useEffect(() => {
    fetch();
  }, [searchParams]);

  const onChangeStatus = async (message) => {
    const response = await sendRequest({
      url: "/admin/messages/" + message.id,
      token: token,
      method: "patch",
    });

    if (response != null) {
      fetch();
    }
  };
  const onSelectedClick = (message) => {
    setSelected(message);
  };

  return (
    <div className="p-4 900px:p-6">
      <ConfirmationModal
        message="Are you sure to delete this message. this cannot be undone"
        onClose={() => {
          setDeleteId(null);
        }}
        type="danger"
        onProcess={() => {
          deleteMessage();
        }}
        show={deleteId != null}
      />
      <MessageModal
        show={selected != null}
        message={selected}
        onClose={() => {
          setSelected(null);
        }}
      />

      <SearchInput
        className="mt-6 "
        setSearchParams={setSearchParams}
        getSearchParams={() => searchParamsToObject(searchParams)}
        options={["name:name", "email:email", "phone:phone number"]}
      />
      <Card className="mt-4">
        <PageTable
          name="message"
          isLoading={isLoading}
          error={error}
          headers={[
            "Name",
            "Email",
            "Phone",
            "Status",
            "description",
            "Action",
          ]}
          cloumnClasses={{ 4: "w-96" }}
          body={messages.map((message) => {
            return [
              message.name,
              message.email,
              message.phone,
              <div className="px-1 py-0.5 rounded text-xs text-center  bg-yellow-200 text-yellow-500 capitalize">
                {message.status}
              </div>,
              <p key="name w-20">{message.message.slice(0, 50)}...</p>,
              <div className="grid gap-x-2.5 grid-flow-col justify-start items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.3}
                  stroke="currentColor"
                  onClick={() => onChangeStatus(message)}
                  className="h-[22px] w-[22px] t-all text-gray-400 hover:text-green-500 rounded-full"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m8.25 3v6.75m0 0l-3-3m3 3l3-3M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
                  />
                </svg>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.3}
                  stroke="currentColor"
                  onClick={() => onSelectedClick(message)}
                  className="h-[22px] w-[22px] t-all text-gray-400 hover:text-blue-500 rounded-full"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                  />
                </svg>

                <svg
                  onClick={() => setDeleteId(message.id)}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  className="h-[22px] w-[22px] t-all text-gray-400 hover:text-red-500 rounded-full"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  />
                </svg>
              </div>,
            ];
          })}
        />

        {paginationData && (
          <Pagination
            currentPage={searchParams.get("page") ?? 1}
            setCurrentPage={(page) =>
              setSearchParams({
                ...getSearchParams(),
                page: page,
              })
            }
            data={paginationData}
            path={location.pathname}
            searchParams={removeQueryParam("page", location.search)}
          />
        )}
      </Card>
    </div>
  );
};
export default InboxPendingIndex;
