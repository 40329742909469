import React, { Fragment, useContext, useEffect } from "react";
import AuthContext from "../../store/admin/AuthContext";
import {
  Navigate,
  Outlet,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";

const IsAuthenticated = (props) => {
  const authContext = useContext(AuthContext);
  const location = useLocation();

  if (
    (authContext.isAuthenticated &&
      authContext.abilities?.includes("role:admin")) ||
    authContext.abilities?.includes("role:super")
  ) {
    return <Outlet />;
  } else {
    return (
      <Navigate
        to={"/admin/authentication/login"}
        state={{ from: location }}
        replace
      />
    );
  }
};
export default IsAuthenticated;
