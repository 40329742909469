import React, { forwardRef } from "react";

const Select = forwardRef((props, ref) => {
  let emptyOption = props.emptyOption ?? true;
  return (
    <div className={props.className}>
      {props.label && (
        <label className="block mb-2 font-medium capitalize">
          {props.label}
        </label>
      )}
      <select
        placeholder={props.placeholder ?? "Enter " + props.label}
        ref={ref}
        type={props.type ?? "text"}
        className={`px-3 py-2 rounded-sm border capitalize outline-none text-gray-500  w-full   ${
          props.error
            ? "border-red-500"
            : "border-gray-300 hover:border-gray-500  focus:border-blue-500"
        }`}
        value={props.model}
        onChange={(e) => {
          props.set(e.target.value);
          props.onChange && props.onChange(e);
        }}
      >
        {emptyOption && (
          <option value="" readOnly>
            -- Select A {props.label} --
          </option>
        )}
        {props.options != null &&
          props.options.map((opt) => (
            <option key={opt.value} value={opt.value} className="capitalize">
              {opt.name}
            </option>
          ))}
      </select>
      {props.error && (
        <p className="text-xs mt-1 text-red-500">{props.error}</p>
      )}
    </div>
  );
});

export default Select;
