import { useEffect, useRef } from "react";

const useUpdate = (fn, args) => {
  let isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current) {
      fn();
    }
  }, args);

  useEffect(() => {
    isMounted.current = true;
  }, []);
};

export default useUpdate;
