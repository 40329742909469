import React, { Fragment } from "react";
import Table from "./table/Table";
import TableRow from "./table/TableRow";
import TableCol from "./table/TableCol";
import SpinnerOveraly from "./SpinnerOverlay";
import EmptyRow from "./table/EmptyRow";
import TableHead from "./table/TableHead";

import src from "../assets/images/no-results.png";
const PageTable = ({
  isLoading,
  error,
  name,
  headers,
  body,
  cloumnClasses,
  tableClassName,
}) => {
  body = body ?? [];
  return (
    <div className="relative">
      {isLoading && !error && <SpinnerOveraly />}
      <Table className={tableClassName}>
        <TableRow>
          {headers.map((head, index) => (
            <TableHead
              key={index}
              className={cloumnClasses ? cloumnClasses[index] : ""}
            >
              {head}
            </TableHead>
          ))}
        </TableRow>
        {(body == null || body.length == 0) && !isLoading && !error && (
          <EmptyRow>
            <img src={src} className="h-20" />
            <h1 className="text-gray-900 mt-4 mb-1 font-semibold text-base ">
              Sorry No Results Found :(
            </h1>
            <p className="text-gray-400">
              We're sorry, {name} data your looking for is not found
            </p>
          </EmptyRow>
        )}

        {body != null &&
          body.map((row, index) => (
            <TableRow key={index} className="odd:bg-[#f9f9f9]">
              {row.map((column, index) => (
                <TableCol key={index}>{column}</TableCol>
              ))}
            </TableRow>
          ))}
      </Table>
    </div>
  );
};
export default PageTable;
