import { Fade } from "@mui/material";
import React from "react";

const Backdrop = (props) => {
  return (
    <Fade in={true} timeout={300}>
      <div
        onClick={props.onClick}
        className={`fixed z-40 top-0 left-0 h-full w-full bg-black bg-opacity-40 ${props.className}`}
      >
        {props.children}
      </div>
    </Fade>
  );
};
export default Backdrop;
