import React from "react";

const Table = (props) => {
  return (
    <div className={`    ${props.className ?? "min-h-[250px] "}`}>
      <table className=" w-full  ">
        <tbody>{props.children}</tbody>
      </table>
    </div>
  );
};
export default Table;
