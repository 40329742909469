import { imageUrl } from "../utils/Statics";
const AlbumCard = ({ album, ...props }) => {
  return (
    <div className="border rounded group t-all" {...props}>
      <div className="overflow-hidden border-b ">
        <img
          src={imageUrl(album.images[0].path)}
          className="object-contain object-center w-full h-40 group-hover:scale-105 t-all "
        />
      </div>
      <div className="px-4 py-2 text-center">
        <h1 className="capitalize text-[18px] font-bold mb-1">{album.name}</h1>
      </div>
    </div>
  );
};

export default AlbumCard;
