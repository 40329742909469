import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import IsAuthenticated from "./security/admin/IsAuthenticated";

import AdminLayout from "./layouts/admin/AdminLayout";

import Login from "./pages/admin/authentication/Login";
import Register from "./pages/admin/authentication/Register";
import Authentication from "./pages/admin/authentication/Authentication";

import DashboardIndex from "./pages/admin/dashboard/DashboardIndex";

import Error from "./pages/error/Error";
import MainLayout from "./layouts/main/MainLayout";

import { AuthProvider } from "./store/admin/AuthContext";

import IsSuperAdmin from "./security/admin/IsSuperAdmin";
import AdminsIndex from "./pages/admin/admins/AdminsIndex";
import Home from "./pages/main/home/Home";
import ServicesIndex from "./pages/admin/services/ServicesIndex";
import TestimonialsIndex from "./pages/admin/testimonials/TestimonialsIndex";
import WorksIndex from "./pages/admin/works/WorksIndex";
import WorkForm from "./pages/admin/works/WorkForm";
import Service from "./pages/main/services/Service";
import Work from "./pages/main/works/Work";
import ClientsIndex from "./pages/admin/clients/ClientsIndex";
import CareersIndex from "./pages/admin/careers/CareersIndex";
import CareerForm from "./pages/admin/careers/CareerForm";
import Careers from "./pages/main/careers/Careers";
import Career from "./pages/main/careers/Career";
import GalleryIndex from "./pages/admin/gallery/GalleryIndex";
import Gallery from "./pages/main/gallery/Gallery";
import ContactUs from "./pages/main/conactus/ContactUs";
import InboxLayout from "./pages/admin/inbox/partials/InboxLayout";
import InboxPendingIndex from "./pages/admin/inbox/InboxPendingIndex";
import InboxArchivedIndex from "./pages/admin/inbox/InboxArchivedIndex";
import AboutUs from "./pages/main/aboutus/AboutUs";
import Clients from "./pages/main/clients/Clients";

const App = (props) => {
  const location = useLocation();
  if (
    location.pathname.includes("/admin") ||
    location.pathname.includes("/authentication")
  ) {
    return (
      <AuthProvider>
        <Routes>
          <Route path="/admin">
            {/* GUEST ROUTES */}
            <Route path="authentication" element={<Authentication />}>
              <Route path="login" element={<Login />} />
              <Route path="register" element={<Register />} />
            </Route>

            {/* AUTH ROUTES */}
            <Route element={<AdminLayout />}>
              {/* --- SUPER ADMIN ROUTES */}
              <Route element={<IsSuperAdmin />}>
                <Route path="admins" element={<AdminsIndex />} />
              </Route>
              {/* --- ANY AUTH ROUTES */}
              <Route element={<IsAuthenticated />}>
                <Route path="" element={<Navigate to={"inbox/admin"} />} />

                <Route path="services" element={<ServicesIndex />} />
                <Route path="testimonials" element={<TestimonialsIndex />} />
                <Route path="clients" element={<ClientsIndex />} />
                <Route path="gallery" element={<GalleryIndex />} />

                <Route path="works" element={<WorksIndex />} />
                <Route path="works/create" element={<WorkForm />} />
                <Route path="works/edit/:workId" element={<WorkForm />} />

                <Route path="careers" element={<CareersIndex />} />
                <Route path="careers/create" element={<CareerForm />} />
                <Route path="careers/edit/:careerId" element={<CareerForm />} />

                <Route path="inbox/*" element={<InboxLayout />}>
                  <Route path="pending" element={<InboxPendingIndex />} />
                  <Route path="archived" element={<InboxArchivedIndex />} />
                </Route>
              </Route>
            </Route>
          </Route>

          {/* *** PAGE ERROR */}
          <Route path="/error" element={<Error />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </AuthProvider>
    );
  } else {
    return (
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route path="" element={<Home />} />
          <Route path="services/:serviceId" element={<Service />} />
          <Route path="works/:workId" element={<Work />} />
          <Route path="careers" element={<Careers />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="clients" element={<Clients />} />

          <Route path="contactus" element={<ContactUs />} />
          <Route path="aboutus" element={<AboutUs />} />
          <Route path="careers/:careerId" element={<Career />} />
        </Route>

        {/* *** PAGE ERROR */}
        <Route path="/error" element={<Error />} />
        <Route path="*" element={<Error />} />
      </Routes>
    );
  }
};
export default App;
