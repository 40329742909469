import MainTitle from "../../../../components/MainTitle";
import Card from "../../../../components/Card";

import src from "../../../../assets/images/why.png";
import minimize from "../../../../assets/images/why-minimize.png";
import adopt from "../../../../assets/images/why-adopt.png";
import reduce from "../../../../assets/images/why-reduce.jpeg";
import sustain from "../../../../assets/images/why-sustain.jpeg";
import enhance from "../../../../assets/images/why-enhance.png";
import security from "../../../../assets/images/why-security.png";
import quality from "../../../../assets/images/why-quality.jpg";

let data = [
  {
    title: "Minimize",
    body: "paper work",
    src: minimize,
  },
  {
    title: "adopt",
    body: "International standards",
    src: adopt,
  },
  {
    title: "reduce",
    body: "administrative costs",
    src: reduce,
  },
  {
    title: "sustain",
    body: "Business workflow",
    src: sustain,
  },
  {
    title: "Enhance",
    body: "Productivity",
    src: enhance,
  },
  {
    title: "High",
    body: "Security and Privacy",
    src: security,
  },
  {
    title: "Assure",
    body: "Quality customer service",
    src: quality,
  },
];

const WhySection = (props) => {
  return (
    <div className="my-8 max-screen-width">
      <MainTitle secondary={"Why you select"} main="soft-master" />
      <p className="mt-6 text-gray-500 text-15px leading-7">
        Soft master services are vary includes technical Consultation,
        application development, business process automation and business and
        application support. Our products are designed in innovated cutting-edge
        manner to meet the rapidly evolving and growing requirements. Our
        comprehensive and flexible products and solutions are designed to
      </p>

      <div className="900px:grid-cols-2 mt-8 grid gap-6 items-center">
        <div className="flex-center">
          <img src={src} className="550px:w-[75%]" />
        </div>
        <div className="text-justify">
          <div className="grid gap-6 650px:grid-cols-2 my-8">
            {data.map((item, index) => (
              <Card
                className={`group p-4 flex items-center   capitalize t-all cursor-auto hover:-translate-y-1 relative ${
                  index + 1 == data.length && "650px:col-span-2"
                }`}
              >
                <div className="bg-white rounded-full z-10 absolute bottom-[50%] -right-[50%]"></div>
                <div className=" mr-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6 text-green-500"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div>
                  <h1 className="text-15px ">
                    <span className="">{item.title}</span> {item.body}
                  </h1>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhySection;
