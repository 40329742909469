import illustartion from "../../../../assets/images/illustration-4.png";
import Button from "../../../../components/Button";
import MainTitle from "../../../../components/MainTitle";
import SecondaryTitle from "../../../../components/SecondaryTitle";
const VissionMissionSection = (props) => {
  return (
    <div className="my-8 max-screen-width grid 900px:grid-cols-2 items-center">
      <div className="grid gap-8 h-min items-center">
        <div
          className=" grid gap-8 h-min items-center"
          style={{ gridTemplateColumns: "80px 1fr" }}
        >
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
          >
            <path
              style={{
                fill: "#FFE356",
              }}
              d="M256,0c141.439,0,256,114.561,256,256S397.439,512,256,512S0,397.439,0,256S114.561,0,256,0z"
            />
            <path
              style={{
                fill: "#FCD34E",
              }}
              d="M38.944,256C163.42,56.265,348.7,56.265,473.176,256C348.7,455.735,163.42,455.735,38.944,256z"
            />
            <path
              style={{
                fill: "#FEFEFE",
              }}
              d="M38.944,256C163.42,72.87,348.7,72.87,473.176,256C348.7,439.13,163.42,439.13,38.944,256z"
            />
            <circle
              style={{
                fill: "#83CF8F",
              }}
              cx="256"
              cy="256"
              r="105.601"
            />
            <circle
              style={{
                fill: "#75BD80",
              }}
              cx="256"
              cy="256"
              r="73.825"
            />
            <circle
              style={{
                fill: "#666666",
              }}
              cx="256"
              cy="256"
              r="38.346"
            />
            <g>
              <circle
                style={{
                  fill: "#FFFFFF",
                }}
                cx="203.677"
                cy="212.517"
                r="18.277"
              />
              <circle
                style={{
                  fill: "#FFFFFF",
                }}
                cx="287.776"
                cy="287.776"
                r="12.424"
              />
            </g>
          </svg>

          <div>
            <SecondaryTitle title={"Vision"} />
            <p className="mt-4 text-gray-500 text-15px leading-7">
              Our vision is to become a leader in the software industry
              recognized for providing value added and quality business
              solutions.
            </p>
          </div>
        </div>
        <div
          className=" grid gap-8 items-center"
          style={{ gridTemplateColumns: "80px 1fr" }}
        >
          <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            className="w-full mr-3 relative  "
            viewBox="0 0 330 330"
          >
            <g id="XMLID_19_">
              <circle
                id="XMLID_20_"
                style={{
                  fill: "#f1Da44",
                }}
                cx="160"
                cy="170"
                r="160"
              />
              <path
                id="XMLID_21_"
                style={{
                  fill: "#FFDA44",
                }}
                d="M160,10c88.365,0,160,71.634,160,160s-71.635,160-160,160"
              />
              <circle
                id="XMLID_22_"
                style={{
                  fill: "#fff",
                }}
                cx="160"
                cy="170"
                r="120"
              />
              <circle
                id="XMLID_23_"
                style={{
                  fill: "#FFDA44",
                }}
                cx="160"
                cy="170"
                r="80"
              />
              <path
                id="XMLID_24_"
                style={{
                  fill: "#FFDA44",
                }}
                d="M160,90c44.183,0,80,35.817,80,80s-35.817,80-80,80"
              />
              <circle
                id="XMLID_25_"
                style={{
                  fill: "#fff",
                }}
                cx="160"
                cy="170"
                r="40"
              />
              <g id="XMLID_26_">
                <path
                  id="XMLID_27_"
                  style={{
                    fill: "#FFFFFF",
                  }}
                  d="M241.611,67.175l-8.031,8.031l-28.62,28.62l-29.34,29.34l-22.397,22.398
			c-5.857,5.857-5.857,15.355,0,21.213c5.857,5.858,15.355,5.858,21.214,0l22.398-22.397l29.34-29.339l28.619-28.62l8.032-8.031
			L241.611,67.175z"
                />
                <polygon
                  id="XMLID_28_"
                  style={{
                    fill: "#FF5023",
                  }}
                  points="241.611,67.175 238.076,91.924 280.502,49.497 287.574,0 249.924,37.649 
			245.146,42.427 		"
                />
                <polygon
                  id="XMLID_29_"
                  style={{
                    fill: "#FF9811",
                  }}
                  points="238.076,91.924 262.825,88.389 287.573,84.853 292.351,80.075 330,42.427 
			280.502,49.497 		"
                />
              </g>
            </g>
          </svg>

          <div>
            <SecondaryTitle title={"Mission"} />
            <p className="mt-4 text-gray-500 text-15px leading-7">
              Empower our client with power of technology thereby enabling them
              to offer value added services to society.
            </p>
          </div>
        </div>
      </div>

      <div className="flex-center hidden 900px:flex">
        <img src={illustartion} className="w-9/12  " />
      </div>
    </div>
  );
};

export default VissionMissionSection;
