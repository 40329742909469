import React, { createContext, Fragment, useEffect, useState } from "react";
import SpinnerOveraly from "../../components/SpinnerOverlay";
import useHttp from "../../hooks/useHttp";

const AuthContext = createContext({
  token: null,
  onLoggedIn: (token, user, abilities) => {},
  onLogout: () => {},
  user: null,
  isLoaded: false,
  isAuthenticated: false,
  abilities: null,
});

export const AuthProvider = (props) => {
  const [token, setToken] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [abilities, setAbilities] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [user, setUser] = useState(null);

  const { error, isLoading, sendRequest } = useHttp(false);

  useEffect(() => {
    let t = localStorage.getItem("admin_token");

    const getuser = async () => {
      if (t != null) {
        let response = await sendRequest({
          url: "/admin/initialize",
          token: t,
        });
        if (response && t) {
          setUser(response.user);
          setAbilities(response.abilities);
          setToken(t);
          setIsAuthenticated(!!response.user);
        }
      }
      setIsLoaded(true);
    };
    getuser();
  }, []);

  const onLoggedIn = (userToken, userData, userAbilities) => {
    localStorage.setItem("admin_token", userToken);
    setToken(userToken);
    setIsAuthenticated(true);
    setUser(userData);
    setAbilities(userAbilities);
  };

  const onLogout = () => {
    localStorage.removeItem("admin_token");
    setToken(null);
    setIsAuthenticated(false);
    setUser(null);
  };
  return (
    <AuthContext.Provider
      value={{
        token: token,
        user: user,
        isLoaded: isLoaded,
        isAuthenticated: isAuthenticated,
        abilities: abilities,
        onLoggedIn: onLoggedIn,
        onLogout: onLogout,
      }}
    >
      {isLoaded && props.children}
      {isLoading && <SpinnerOveraly />}
    </AuthContext.Provider>
  );
};
export default AuthContext;
