import { Link } from "react-router-dom";
import { imageUrl } from "../utils/Statics";

const ProjectCard = ({ work }) => {
  return (
    <Link
      to={"/works/" + work.id}
      className="block h-full overflow-hidden bg-gray-100 rounded hover:bg-primary group hover:text-white t-all"
    >
      <div className="overflow-hidden">
        <img
          src={imageUrl(work.images[0].path)}
          className="object-cover object-center w-full h-64 t-all group-hover:scale-125 group-hover:rotate-12"
        />
      </div>
      <div className="px-4 py-6 text-center">
        <p className="font-medium uppercase text-primary group-hover:text-white">
          {work.service.name}
        </p>
        <h1 className="font-bold text-[16px] my-1 capitalize">{work.name}</h1>
        <p className="text-sm text-gray-500 capitalize group-hover:text-gray-200">
          {work.company}
        </p>
        <div className="flex items-center justify-center mt-4">
          Read More
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1}
            stroke="currentColor"
            className="w-4 h-4 ml-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
            />
          </svg>
        </div>
      </div>
    </Link>
  );
};

export default ProjectCard;
