import Breadcrumb from "../../../components/Breadcrumb";
import BasicInfoSection from "./partials/BasicInfoSection";
import StaticsSection from "../home/partials/StaticsSection";
import StrategySection from "./partials/StrategySection";
import VissionMissionSection from "./partials/VissionMissionSection";
import WhySection from "./partials/WhySection";
import CompetitionKeysSection from "./partials/CompetitionKeysSection";
import StaffsSection from "./partials/StaffsSection";
const AboutUs = (props) => {
  return (
    <div className="">
      <Breadcrumb
        main={"about us"}
        secondary={"Soft-master technologies (pvt) ltd"}
      />
      <BasicInfoSection />
      <StaticsSection />
      <VissionMissionSection />
      <WhySection />
      <CompetitionKeysSection />
      <StaffsSection />
      <StrategySection />
    </div>
  );
};

export default AboutUs;
