const StaticsCard = ({ logo, count, name }) => {
  return (
    <div className="px-4 py-10 bg-white rounded flex-center bg-opacity-30">
      {logo}
      <p className="text-[32px] font-semibold mt-2 mb-1">{count}</p>
      <p className="text-15px">{name}</p>
    </div>
  );
};

export default StaticsCard;
