import { Zoom } from "@mui/material";
import React from "react";
import Backdrop from "./Backdrop";
import Button from "./Button";
import Card from "./Card";
/**
 *
 * @param {{
 * type;show;message;onClose:"() => {}";onProcess
 * }} props Props for the component
 *
 */
const ConfirmationModal = (props) => {
  let type = props.type ?? "danger";
  return (
    <>
      {props.show && <Backdrop onClick={props.onClose}></Backdrop>}

      {props.show && (
        <div className="flex-center z-40 fixed top-0 left-0 w-full h-full">
          <Zoom unmountOnExit in={props.show} className="duration-500">
            <div>
              <Card
                className={`p-6 bg-white w-11/12 w-[400px] border-0 border-t-4 relative ${
                  type == "danger" && "border-red-500"
                } ${type == "success" && "border-green-500"} ${
                  type == "info" && "border-blue-500"
                } ${type == "warning" && "border-yellow-600"}`}
              >
                {type == "danger" && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-16 w-16 -top-8 p-1.5 bg-red-500 text-white absolute rounded-full"
                    style={{
                      left: "calc(50% - 32px)",
                    }}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="1.3"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                )}

                {type == "success" && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      left: "calc(50% - 32px)",
                    }}
                    className="h-16 w-16 -top-8 p-1.5 bg-green-500 text-white absolute rounded-full"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="1.3"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                )}

                {type == "warning" && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-16 w-16 -top-8 p-1.5 bg-yellow-600 text-white absolute rounded-full"
                    fill="none"
                    style={{
                      left: "calc(50% - 32px)",
                    }}
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="1.3"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                )}

                {type == "info" && (
                  <svg
                    style={{
                      left: "calc(50% - 32px)",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-16 w-16 -top-8 p-1.5 bg-blue-600 text-white absolute rounded-full"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="1.3"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                )}
                <div className="py-4 ">
                  <p className="text-center w-10/12 mx-auto text-gray-700">
                    {props.message}
                  </p>
                  <div className="grid grid-flow-col gap-4 items-center justify-center mt-4">
                    <Button onClick={props.onClose} type="dark">
                      Cancel
                    </Button>
                    <Button onClick={props.onProcess} type={type}>
                      Confirm
                    </Button>
                  </div>
                </div>
              </Card>
            </div>
          </Zoom>
        </div>
      )}
    </>
  );
};
export default ConfirmationModal;
