import React from "react";

const Overlay = (props) => {
  return (
    <div className="absolute h-full  min-h-screen-200px z-10 w-full top-0 left-0 flex-center bg-white bg-opacity-40">
      {props.children}
    </div>
  );
};
export default Overlay;
