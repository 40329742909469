import React, { useContext, useEffect, useRef, useState } from "react";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import Textarea from "../../../../components/Textarea";

import Modal from "../../../../components/Modal";
import Overlay from "../../../../components/Overlay";
import SpinnerOveraly from "../../../../components/SpinnerOverlay";
import useHttp from "../../../../hooks/useHttp";
import AuthContext from "../../../../store/admin/AuthContext";

const TestimonialModal = (props) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [info, setInfo] = useState("");

  const { error, isLoading, sendRequest, validationErros } = useHttp();
  const authContext = useContext(AuthContext);

  useEffect(() => {
    const getTestimonial = async () => {
      if (props.testimonial != null) {
        setValues(props.testimonial);
      }
    };
    getTestimonial();
  }, [props.testimonial]);

  const onSubmit = async (event) => {
    event.preventDefault();
    const response = await sendRequest({
      url: props.testimonial
        ? "/admin/testimonials/" + props.testimonial.id
        : "/admin/testimonials",
      method: props.testimonial ? "PUT" : "POST",
      body: {
        name: name,
        info: info,
        description: description,
      },
      token: authContext.token,
    });

    if (response != null) {
      resetValues();

      props.addAlert(
        "success",
        props.testimonial ? "Updated" : "Created",
        `Testimonial ${props.testimonial ? "Updated" : "Created"} Successfully`
      );
      props.onComplete();
    }
  };

  const resetValues = () => {
    setName("");
    setInfo("");
    setDescription("");
  };

  const setValues = (testimonial) => {
    setName(testimonial.name);
    setDescription(testimonial.description);
    setInfo(testimonial.info);
  };

  return (
    <Modal
      show={props.show}
      title={`${props.testimonial ? "Update" : "Create"} Testimonial`}
      onClose={() => {
        resetValues();
        props.onClose();
      }}
    >
      <form onSubmit={onSubmit} className="">
        <div className="relative grid gap-4">
          {isLoading && !error && <Overlay />}
          <Input
            model={name}
            set={setName}
            label="name"
            error={validationErros?.errors?.name ?? null}
          />
          <Input
            model={info}
            set={setInfo}
            label="Person Info"
            error={validationErros?.errors?.info ?? null}
            placeholder="e.g. Director, Softmaster"
          />
          <Textarea
            model={description}
            set={setDescription}
            label="description"
            error={validationErros?.errors?.description ?? null}
          />
        </div>
        <div className="flex-center mt-4">
          <Button isLoading={isLoading}>
            {props.testimonial ? "Update" : "Create"} Testimonial
          </Button>
        </div>
      </form>
    </Modal>
  );
};
export default TestimonialModal;
