import React from "react";
import { Link } from "react-router-dom";
import Button from "./Button";
const AddButton = (props) => {
  const type = props.type && props.type != "button" ? props.type : "primary";
  if (props.to != null) {
    return (
      <Link
        className={` flex items-center justify-between px-4 py-2 text-sm min-w-4 t-all ${
          (type == "primary" || type == "info") && "bg-primary"
        } ${type == "success" && "bg-green-500"} ${
          type == "dark" && "bg-darkBlue"
        } ${type == "warning" && "bg-yellow-600"} ${
          type == "danger" && "bg-red-500"
        } text-white rounded-sm shadow-md relative font-bold min-w-fit overflow-hidden ${
          props.className
        }`}
        to={props.to}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="1.3"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
          />
        </svg>
        {props.children}
      </Link>
    );
  } else {
    return (
      <Button
        {...props}
        className={`flex items-center justify-between ${props.className}`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="1.3"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
          />
        </svg>
        {props.children}
      </Button>
    );
  }
};
export default AddButton;
