import React from "react";
import "../assets/css/spinner.css";
import Overlay from "./Overlay";
import Spinner from "./Spinner";
const SpinnerOveraly = (props) => {
  let SelectedSpinner = props.spinner ?? Spinner;
  return (
    <Overlay>
      {/* <SelectedSpinner className="w-10 h-10 text-primary" /> */}
      <span class="loader5"></span>
    </Overlay>
  );
};
export default SpinnerOveraly;
