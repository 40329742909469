import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import PageSpinner from "../../../components/PageSpinner";
import SecondaryTitle from "../../../components/SecondaryTitle";
import SpinnerOveraly from "../../../components/SpinnerOverlay";

import useHttp from "../../../hooks/useHttp";
import { imageUrl } from "../../../utils/Statics";
let thumbOptions = {
  fixedWidth: 80,
  fixedHeight: 100,
  gap: 10,
  perPage: 4,
  rewind: true,
  arrows: false,
  pagination: false,
  cover: true,
  isNavigation: true,
  mediaQuery: "min",

  breakpoints: {
    1000: {
      direction: "ttb",
      height: "440px",
    },
  },
};
const Work = (props) => {
  const [work, setWork] = useState(null);
  let thumbRef = useRef();
  let mainRef = useRef();

  useEffect(() => {
    if (mainRef.current) {
      mainRef.current.sync(thumbRef.current.splide);
    }
  }, [work]);
  const { error, isLoading, sendRequest } = useHttp();
  let workId = useParams().workId;
  const fetch = async () => {
    const response = await sendRequest({
      url: "/works/" + workId,
    });

    if (response != null) {
      setWork(response);
    }
  };
  useEffect(() => {
    fetch();
  }, []);
  return (
    <>
      <div className="">
        <Breadcrumb
          main={work?.name}
          mainClassName="600px:text-[32px] text-[28px]"
          secondary={"Work"}
        />
        <div className="relative min-h-32">
          {isLoading && <SpinnerOveraly />}
          {work && (
            <div className="my-8 max-screen-width ">
              <div
                className="gap-6 1000px:grid"
                style={{ gridTemplateColumns: "1fr 100px" }}
              >
                <div className="relative h-full bg-black">
                  <Splide
                    ref={mainRef}
                    options={{
                      type: "loop",
                      arrows: true,
                      pagination: true,
                      perPage: 1,
                      perMove: 1,
                    }}
                  >
                    {work?.images.map((img) => (
                      <SplideSlide key={img.id}>
                        <div className="flex-center">
                          <img
                            src={imageUrl(img.path)}
                            className="object-cover object-center max-w-full h-[440px]"
                          />
                        </div>
                      </SplideSlide>
                    ))}
                    {work?.video && (
                      <SplideSlide key={work.video.id}>
                        <div className="flex-center">
                          <video
                            className="object-cover object-center max-w-full h-[440px]"
                            controls
                          >
                            <source src={imageUrl(work.video.path)} />
                          </video>
                        </div>
                      </SplideSlide>
                    )}
                  </Splide>
                </div>

                <Splide
                  className="flex items-center justify-center mt-4 1000px:justify-start 1000px:mt-0"
                  options={thumbOptions}
                  ref={thumbRef}
                >
                  {work?.images.map((img) => (
                    <SplideSlide key={img.id} className=" thumb-img">
                      <img
                        src={imageUrl(img.path)}
                        style={{ aspectRatio: "1/1" }}
                      />
                    </SplideSlide>
                  ))}

                  {work?.video && (
                    <SplideSlide key={work.video.id}>
                      <div
                        className="flex-center"
                        style={{ aspectRatio: "1/1" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-10 h-10 mt-4 fill-primary"
                        >
                          <path
                            fillRule="evenodd"
                            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm14.024-.983a1.125 1.125 0 010 1.966l-5.603 3.113A1.125 1.125 0 019 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    </SplideSlide>
                  )}
                </Splide>
              </div>

              <div
                dangerouslySetInnerHTML={{
                  __html: work.description.replace(
                    `<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>`,
                    ""
                  ),
                }}
                className="mt-6 html_editor 650px:mt-8"
              ></div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Work;
