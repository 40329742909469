import React from "react";
import TableCol from "./TableCol";
import TableRow from "./TableRow";

const EmptyRow = (props) => {
  return (
    <TableRow>
      <TableCol colSpan="100%" className=" text-gray-600">
        <div className="text-center py-4 mt-4 flex-center">
          {props.children}
        </div>
      </TableCol>
    </TableRow>
  );
};
export default EmptyRow;
