import { Collapse } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import Button from "../../../components/Button";
const ToolbarDrawer = ({ setShowDrawer, showDrawer, services }) => {
  const [showServices, setShowServices] = useState(false);

  return (
    <div
      className={`fixed top-0 left-0 z-20 w-full h-full bg-white transition-all transform duration-500 ${
        showDrawer ? "translate-y-0" : "-translate-y-full"
      }`}
    >
      <div className="py-3 flex-between max-screen-width border-b-1">
        <img src={logo} className="h-12 850px:h-16" />

        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          onClick={() => setShowDrawer((prev) => !prev)}
          className={`w-8 h-8 text-gray-500 850px:hidden t-all hover:text-primary`}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
      <div className="grid gap-2 p-4">
        <Link
          to={"/"}
          className="px-2 py-2 text-sm font-semibold border-transparent border-l-3 t-all hover:text-primary hover:border-primary"
        >
          HOME
        </Link>
        <Link
          to={"/aboutus"}
          className="px-2 py-2 text-sm font-semibold border-transparent border-l-3 t-all hover:text-primary hover:border-primary"
        >
          ABOUT US
        </Link>
        <div className="group">
          <p
            onClick={() => setShowServices((prev) => !prev)}
            className="px-2 py-2 text-sm font-semibold border-transparent flex-between border-l-3 t-all group-hover:text-primary group-hover:border-primary"
          >
            <span>SERVICES</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className={`w-5 h-5 group-hover:text-primary t-all ${
                showServices
                  ? "rotate-90 text-primary"
                  : "rotate-0 text-gray-500"
              }`}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          </p>
          <Collapse in={showServices} unmountOnExit>
            <div className="grid gap-4 pl-3 mx-3 mt-2 border-l-2 border-primary">
              {services.map((service) => (
                <Link
                  to={"services/" + service.id}
                  className="text-sm font-medium text-gray-500 t-all hover:text-primary"
                >
                  {service.name}
                </Link>
              ))}
            </div>
          </Collapse>
        </div>

        <Link
          to={"/careers"}
          className="px-2 py-2 text-sm font-semibold border-transparent border-l-3 t-all hover:text-primary hover:border-primary"
        >
          CAREERS
        </Link>
        <Link
          to={"/gallery"}
          className="px-2 py-2 text-sm font-semibold border-transparent border-l-3 t-all hover:text-primary hover:border-primary"
        >
          GALLERY
        </Link>
        <Link
          to={"/clients"}
          className="px-2 py-2 text-sm font-semibold border-transparent border-l-3 t-all hover:text-primary hover:border-primary"
        >
          CLIENTS
        </Link>

        <Link
          to={"/contactus"}
          className="px-2 py-2 text-sm font-semibold border-transparent border-l-3 t-all hover:text-primary hover:border-primary"
        >
          CONTACT US
        </Link>
      </div>
    </div>
  );
};

export default ToolbarDrawer;
