import React, { useEffect, useState, useLayoutEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import "@splidejs/react-splide/css";
import "../../assets/css/main.css";
import useScroll from "../../hooks/useScroll";
import Toolbar from "./partials/Toolbar";
import Footer from "./partials/Footer";
import useHttp from "../../hooks/useHttp";

const MainLayout = (props) => {
  const { scroll } = useScroll();
  const location = useLocation();
  const { sendRequest, isLoading } = useHttp();

  const [services, setServices] = useState([]);
  useLayoutEffect(() => {
    scroll();
  }, [location.pathname]);

  useEffect(() => {
    const init = async () => {
      let response = await sendRequest({
        url: "/initialize",
      });
      if (response) {
        setServices(response.services);
      }
    };

    init();
  }, []);
  return (
    <div className="bg-white ">
      <Toolbar services={services} />

      <div className="relative w-full min-h-[90vh]  ">
        <div className="w-full " id="contents">
          <Outlet />
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default MainLayout;
