import React, { useContext, useEffect, useRef, useState } from "react";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import Textarea from "../../../../components/Textarea";

import Modal from "../../../../components/Modal";
import Overlay from "../../../../components/Overlay";
import SpinnerOveraly from "../../../../components/SpinnerOverlay";
import useHttp from "../../../../hooks/useHttp";
import AuthContext from "../../../../store/admin/AuthContext";

const ServiceModal = (props) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [icon, setIcon] = useState(null);

  const { error, isLoading, sendFormRequest, validationErros } = useHttp();
  const authContext = useContext(AuthContext);

  useEffect(() => {
    const getService = async () => {
      if (props.service != null) {
        setValues(props.service);
      }
    };
    getService();
  }, [props.service]);

  const onSubmit = async (event) => {
    event.preventDefault();

    let formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("icon", icon ?? "");

    if (props.service) {
      formData.append("_method", "PATCH");
    }

    const response = await sendFormRequest({
      url: props.service
        ? "/admin/services/" + props.service.id
        : "/admin/services",
      body: formData,
      token: authContext.token,
    });

    if (response != null) {
      resetValues();

      props.addAlert(
        "success",
        props.service ? "Updated" : "Created",
        `Service ${props.service ? "Updated" : "Created"} Successfully`
      );
      props.onComplete();
    }
  };

  const resetValues = () => {
    setName("");
    setIcon("");
    setDescription("");
  };

  const setValues = (service) => {
    setName(service.name);
    setDescription(service.description);
    setIcon(service.icon);
  };

  return (
    <Modal
      show={props.show}
      title={`${props.service ? "Update" : "Create"} Service`}
      onClose={() => {
        resetValues();
        props.onClose();
      }}
    >
      <form onSubmit={onSubmit} className="">
        <div className="relative grid gap-4">
          {isLoading && !error && <Overlay />}
          <Input
            model={name}
            set={setName}
            label="name"
            error={validationErros?.errors?.name ?? null}
          />
          <Textarea
            model={description}
            set={setDescription}
            label="description"
            error={validationErros?.errors?.description ?? null}
          />
          <div>
            {/* <Input
              model={icon}
              onChange={(e) => {
                let i = e.target.files[0];
                setIcon(i);
              }}
              label={`${props.service ? "New" : ""} Service Icon`}
              error={validationErros?.errors?.icon ?? null}
              type="file"
              accept=".svg"
            /> */}
            <Textarea
              model={icon}
              set={setIcon}
              label={`${props.service ? "New" : ""} Service Icon`}
              error={validationErros?.errors?.icon ?? null}
            />
            <div className="font-muted mt-2 grid gap-0.5">
              <p className="">
                <span>1. </span>
                <a
                  target="_blank"
                  href="https://heroicons.com/"
                  className="text-blue-500 font-medium t-all hover:underline"
                >
                  Click here
                </a>
                <span> to find icon </span>
              </p>
              <p className="">2. Hover Over Icon</p>
              <p className="">3. Copy as JSX & Past In Above Field</p>
            </div>
          </div>
        </div>
        <div className="flex-center mt-4">
          <Button isLoading={isLoading}>
            {props.service ? "Update" : "Create"} Service
          </Button>
        </div>
      </form>
    </Modal>
  );
};
export default ServiceModal;
