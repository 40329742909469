import React, { useContext } from "react";
import AuthContext from "../../../store/admin/AuthContext";

const Toolbar = (props) => {
  const { user, isAuthenticated } = useContext(AuthContext);
  return (
    <div className="main-section__toolbar">
      <div className="  bg-white border-b border-gray-200 my-auto px-4  flex items-center justify-between ">
        <svg
          onClick={props.onMenuClick}
          xmlns="http://www.w3.org/2000/svg"
          className="h-10 w-10 p-2 hover:bg-gray-200 rounded-full text-gray-600 t-all hover:text-gray-900"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="1.5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
        {isAuthenticated && (
          <div className="ml-auto">
            <p className="font-medium text-sm text-right capitalize">
              {user.name}
            </p>
            <p className=" text-gray-500 text-xs">{user.email}</p>
          </div>
        )}
      </div>
    </div>
  );
};
export default Toolbar;
