import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import useScroll from "../hooks/useScroll";
// current_page: 1;
// first_page_url: "http://127.0.0.1:8000/api/companies?page=1";
// from: 1;
// last_page: 4;
// last_page_url: "http://127.0.0.1:8000/api/companies?page=4";
// next_page_url: "http://127.0.0.1:8000/api/companies?page=2";
// path: "http://127.0.0.1:8000/api/companies";
// per_page: 1;
// prev_page_url: null;
// to: 1;
// total: 4;
const Pagination = ({
  data,
  path,
  setCurrentPage,
  currentPage,
  searchParams,
}) => {
  const { scroll } = useScroll();
  useEffect(() => {
    scroll();
  }, [path]);
  let pages = [];
  for (let index = 1; index <= data.last_page; index++) {
    pages.push(
      <Link
        key={index}
        className={`h-8 w-8 p-1.5 flex-center shadow-md rounded-full  hover:bg-darkBlue hover:text-white transition-all ${
          currentPage == index ? "bg-darkBlue text-white" : "text-gray-600"
        }`}
        to={{
          pathname: path,
          search: `?page=${index}${
            searchParams && searchParams.length > 0 ? "&" + searchParams : ""
          }`,
        }}
      >
        {index}
      </Link>
    );
  }

  return (
    <div className="grid gap-4 justify-center items-center mt-4 grid-flow-col">
      {currentPage != 1 ? (
        <NavLink
          to={
            currentPage != 1
              ? {
                  pathname: path,
                  search: `?page=${currentPage - 1}${
                    searchParams && searchParams.length > 0
                      ? "&" + searchParams
                      : ""
                  }`,
                }
              : ""
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-8 w-8 p-1.5 shadow-md rounded-full text-gray-600 hover:bg-darkBlue hover:text-white t-all"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="1.5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </NavLink>
      ) : (
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-8 w-8 p-1.5 shadow-md rounded-full text-gray-400 bg-gray-200"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="1.5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </div>
      )}

      {pages}

      {data.last_page != currentPage ? (
        <NavLink
          to={{
            pathname: path,
            search: `?page=${+currentPage + 1}${
              searchParams && searchParams.length > 0 ? "&" + searchParams : ""
            }`,
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-8 w- p-1.5 shadow-md rounded-full text-gray-600 hover:bg-darkBlue hover:text-white transition-all"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="1.3"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </NavLink>
      ) : (
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-8 w- p-1.5 shadow-md rounded-full text-gray-400 bg-gray-200 transition-all"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="1.3"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </div>
      )}
    </div>
  );
};
export default Pagination;
