import React, { useEffect, useState, useContext } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import PageTitle from "../../../components/PageTitle";
import useHttp from "../../../hooks/useHttp";
import AuthContext from "../../../store/admin/AuthContext";
import AlertContext from "../../../store/common/AlertContext";
import SearchInput from "../../../components/SearchInput";
import PageTable from "../../../components/PageTable";
import ConfirmationModal from "../../../components/ConfirmationModal";

import AdminModal from "./partials/AdminModal";
import AddButton from "../../../components/AddButton";
import Card from "../../../components/Card";
import { removeQueryParam, searchParamsToObject } from "../../../utils/Statics";

const AdminsIndex = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [edit, setEdit] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  const [paginationData, setPaginationData] = useState(null);
  const [admins, setAdmins] = useState([]);

  const { error, isLoading, sendRequest } = useHttp();
  const { token } = useContext(AuthContext);
  const { addAlert } = useContext(AlertContext);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const fetch = async () => {
    const response = await sendRequest({
      url: "/admin/admins",
      token: token,
      queryParams: {
        page: searchParams.get("page") ?? 1,
        column: searchParams.get("column") ?? "",
        value: searchParams.get("value") ?? "",
      },
    });

    if (response != null) {
      setShowModal(false);
      setEdit(null);
      setAdmins(response.data?.length > 0 ? response.data : []);
      delete response.data;
      setPaginationData(response.last_page != 1 ? response : null);
    }
  };

  useEffect(() => {
    fetch();
  }, [searchParams]);

  const onDeleteHandler = async () => {
    if (deleteId != null) {
      const response = await sendRequest({
        url: "/admin/admins/" + deleteId,
        token: token,
        method: "DELETE",
      });
      if (response != null) {
        fetch();
        addAlert("primary", "Deleted", "Admin deleted successfully");
        setDeleteId(null);
      }
    }
  };

  const onEditClick = (admin) => {
    setEdit(admin);
    setShowModal(true);
  };

  const onDeleteClick = (id) => {
    setDeleteId(id);
  };

  return (
    <div className="p-4 900px:p-6">
      <AdminModal
        show={showModal}
        addAlert={addAlert}
        admin={edit}
        onComplete={fetch}
        onClose={() => {
          setShowModal(false);
          setEdit(null);
        }}
      />
      <ConfirmationModal
        message="Are you sure to delete this admin. this cannot be undone"
        onClose={() => {
          setDeleteId(null);
        }}
        onProcess={onDeleteHandler}
        show={!!deleteId}
      />
      <div className="flex items-center justify-between flex-wrap">
        <PageTitle className="mt-4 mr-10">admins</PageTitle>
        <AddButton
          className="mt-4"
          onClick={() => {
            setShowModal(true);
          }}
        >
          Create Admin
        </AddButton>
      </div>

      <SearchInput
        className="mt-6 "
        setSearchParams={setSearchParams}
        getSearchParams={() => searchParamsToObject(searchParams)}
        options={["name:name"]}
      />

      <Card className="mt-4">
        <PageTable
          name="Admin"
          isLoading={isLoading}
          error={error}
          headers={["id", "name", "action"]}
          body={admins.map((admin) => {
            return [
              admin.id,
              <p className="capitalize" key="name">
                {admin.name}
              </p>,
              <div className="grid gap-3 grid-flow-col justify-start items-center">
                <svg
                  onClick={() => onEditClick(admin)}
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-[22px] w-[22px] t-all text-gray-400 hover:text-blue-500 rounded-full"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.3"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                  />
                </svg>

                <svg
                  onClick={() => onDeleteClick(admin.id)}
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-[22px] w-[22px] t-all text-gray-400 hover:text-red-500 rounded-full"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.3"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </div>,
            ];
          })}
        />

        {paginationData && (
          <Pagination
            currentPage={searchParams.get("page") ?? 1}
            setCurrentPage={(page) =>
              setSearchParams({
                ...getSearchParams(),
                page: page,
              })
            }
            data={paginationData}
            path={location.pathname}
            searchParams={removeQueryParam("page", location.search)}
          />
        )}
      </Card>
    </div>
  );
};
export default AdminsIndex;
