import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import Card from "../../../components/Card";
import Input from "../../../components/Input";
import MessageBox from "../../../components/MessageBox";
import Overlay from "../../../components/Overlay";
import Spinner from "../../../components/Spinner";
import SpinnerOveraly from "../../../components/SpinnerOverlay";
import useHttp from "../../../hooks/useHttp";
import AuthContext from "../../../store/admin/AuthContext";

const Login = (props) => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [password, setPassword] = useState("");
  const [isEmpty, setIsEmpty] = useState(false);

  const [email, setEmail] = useState("");

  const { error, isLoading, sendRequest, validationErros } = useHttp();

  const onLogin = async (event) => {
    event.preventDefault();
    let response = await sendRequest({
      url: "/admins/login",
      method: "POST",
      body: {
        password: password,
        email: email,
      },
    });

    if (response) {
      authContext.onLoggedIn(response.token, response.user, response.abilities);
      navigate("/admin/inbox/pending");
    }
  };

  const getAdminCount = async () => {
    let response = await sendRequest({
      url: "/admins/count",
    });

    if (response == 0) {
      setIsEmpty(true);
    }
  };

  useEffect(() => {
    getAdminCount();
  }, []);

  return (
    <div className="h-full w-96 ">
      <div className="w-full">
        <h1 className="mb-4 text-xl font-bold text-left">Login</h1>
        <form onSubmit={onLogin} className="grid w-full gap-6">
          <div className="relative grid w-full gap-4">
            {isLoading && <Overlay />}
            <Input
              label="Email"
              model={email}
              set={setEmail}
              error={validationErros?.errors?.email ?? null}
            />
            <Input
              label="Password"
              type="password"
              model={password}
              set={setPassword}
              error={validationErros?.errors?.password ?? null}
            />
          </div>

          <Button className="w-full " isLoading={isLoading}>
            Login
          </Button>
          {validationErros && !validationErros.errors && (
            <MessageBox>{validationErros.message}</MessageBox>
          )}
        </form>
        {isEmpty && (
          <p className="mt-4">
            Create Your First Account?{" "}
            <Link className="link" to={"/admin/authentication/register"}>
              Register
            </Link>
          </p>
        )}
      </div>
    </div>
  );
};

export default Login;
