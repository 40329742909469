import MainTitle from "../../../../components/MainTitle";
import TestomonialCard from "../../../../components/TestomonialCard";
import { Splide, SplideSlide } from "@splidejs/react-splide";
const TestomonialSection = ({ testimonials }) => {
  return (
    <div className="bg-primary bg-opacity-90 text-white my-8 py-12 relative">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="100%"
        height="100%"
        preserveAspectRatio="none"
        viewBox="0 0 1440 560"
        className="absolute top-0 left-0"
      >
        <g mask='url("#SvgjsMask1028")' fill="none">
          <path
            d="M573.467,792.971C626.363,788.558,663.786,747.074,690.156,701.007C716.304,655.328,734.209,601.56,709.696,554.983C683.765,505.711,629.144,479.72,573.467,479.286C516.983,478.845,463.904,505.22,433.504,552.828C400.49,604.529,386.463,670.77,417.352,723.768C448.072,776.477,512.67,798.044,573.467,792.971"
            fill="rgba(255, 255, 255, 0.1)"
            class="triangle-float3"
          ></path>
          <path
            d="M510.14848069594905 166.3437927024692L630.7159999279219 267.5119536219772 611.316641615457 45.776273470496335z"
            fill="rgba(255, 255, 255, 0.1)"
            class="triangle-float2"
          ></path>
          <path
            d="M206.1623945805784 392.60664588899135L299.6710416583768 272.92103552406735 179.9854312934528 179.41238844626895 86.4767842156544 299.09799881119295z"
            fill="rgba(255, 255, 255, 0.1)"
            class="triangle-float1"
          ></path>
          <path
            d="M504.40958335317697 375.37448109310975L451.92547522864527 335.8248688498359 418.84311092600035 434.32583715827036z"
            fill="rgba(255, 255, 255, 0.1)"
            class="triangle-float3"
          ></path>
          <path
            d="M91.98177532683931-25.183869530861838L-17.25667368943377 66.30080767379016 99.02624196442567 110.93763688236356z"
            fill="rgba(255, 255, 255, 0.1)"
            class="triangle-float3"
          ></path>
          <path
            d="M245.99 321.23 a171.87 171.87 0 1 0 343.74 0 a171.87 171.87 0 1 0 -343.74 0z"
            fill="rgba(255, 255, 255, 0.1)"
            class="triangle-float2"
          ></path>
        </g>
        <defs>
          <mask id="SvgjsMask1028">
            <rect width="1440" height="560" fill="#ffffff"></rect>
          </mask>
        </defs>
      </svg>
      <div className="max-screen-width relative z-10">
        <MainTitle
          secondary={"testomonial"}
          main="What Client say about softmasters"
          secondaryColor={"third"}
        />
        <div className="w-full pt-8">
          <Splide
            options={{
              type: "loop",
              width: "calc(100vw - 32px)",
              arrows: false,
              pagination: false,
              autoplay: true,
              interval: 2000,
              easing: "cubic-bezier(0.25, 1, 0.5, 1)",
              perPage: 1,
              perMove: 1,
              gap: 32,
              mediaQuery: "min",
              breakpoints: {
                900: {
                  perPage: 2,
                },
              },
            }}
          >
            {testimonials.map((testimonial) => (
              <SplideSlide key={testimonial.id}>
                <TestomonialCard testimonial={testimonial} />
              </SplideSlide>
            ))}
          </Splide>
        </div>
      </div>
    </div>
  );
};

export default TestomonialSection;
