import MainTitle from "../../../../components/MainTitle";
import illustartion from "../../../../assets/images/illustration-7.png";
import isoimg4 from "../../../../assets/images/ISO4.jpg";
import isoimg2 from "../../../../assets/images/iso9001.jpg";

const BasicInfoSection = (props) => {
  return (
    <div className="row">
      <div className="grid gap-6 mt-4 mb-8 900px:grid-cols-12 max-screen-width">
        <div className="flex flex-col justify-center order-2 900px:col-span-7 ">
          <MainTitle main={"Leading Info"} secondary="" />
          <p
            className="mt-4 leading-7 text-gray-500"
            style={{ fontSize: "14px" }}
          >
            <strong>Soft-master Technologies(Pvt)Ltd (SMT)</strong> provided
            high quality programming and project management solutions is
            established as a well reputed IT firm in Sri Lanka marketplace since
            2000
            <div className="h-2"></div>
            Growing from strength-to-strength, We have proven to be one of the
            bests in the ICT industry with the knowledge and the right set of
            skills to present a unique value proposition that provides the best
            in terms of productivity and return on investment for businesses.
          </p>
        </div>
        <div className="flex-center 900px:col-span-5">
          <img
            src={illustartion}
            className="w-6/12 900px:w-auto 900px:h-[300px] object-cover"
          />
        </div>
      </div>
      <div className="grid gap-6 mt-4 mb-8 900px:grid-cols-12 max-screen-width">
        <div className="flex flex-col justify-center order-2 900px:col-span-7 ">
          <MainTitle main={"ISO Certified "} secondary="" />
          <p
            className="mt-4 leading-7 text-gray-500"
            style={{ fontSize: "14px" }}
          >
            <strong>Softmaster </strong> is an{" "}
            <strong>ISO 9001 certified </strong> software company, which means
            it maintains industry-standard quality to develop and maintain
            projects, as well as the entire company's operational processes.
          </p>
        </div>
        <div className="flex-center 900px:col-span-5">
          <img
            src={isoimg2}
            className="w-6/12 900px:w-auto 900px:h-[300px] object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default BasicInfoSection;
