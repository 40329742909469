import React from "react";
import { Slide, Zoom } from "@mui/material";
import Backdrop from "./Backdrop";
import Card from "./Card";
import PageTitle from "./PageTitle";

const Modal = (props) => {
  return (
    <>
      {props.show && <Backdrop onClick={props.onClose}></Backdrop>}

      <Slide direction="up" in={props.show} timeout={300} unmountOnExit>
        <div className="flex items-end 600px:items-center justify-center z-40 fixed top-0 left-0 w-full h-full">
          <Card
            className={`p-0 bg-white w-full max-w-2xl relative ${props.className}`}
          >
            <div
              className="relative h-full grid"
              style={{ gridTemplateRows: "auto 1fr" }}
            >
              <div className=" border-b px-4 py-2 flex items-center justify-between">
                <p className="text-base font-semibold">{props.title}</p>
                <svg
                  onClick={props.onClose}
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-10 w-10 p-2 t-all hover:bg-gray-200 rounded-full"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
              <div className="pt-4 p-4 overflow-auto max-h-[80vh] ">
                {props.children}
              </div>
            </div>
          </Card>
        </div>
      </Slide>
    </>
  );
};
export default Modal;
