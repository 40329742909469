import MainTitle from "../../../../components/MainTitle";
import illustartion from "../../../../assets/images/illustration-5.jpg";
const StaffsSection = (props) => {
  return (
    <div className="grid gap-6 my-8 900px:grid-cols-2 max-screen-width">
      <div className="flex flex-col justify-center order-2 ">
        <MainTitle main={"Our Staff "} secondary="Professionals" />
        <p className="mt-4 leading-7 text-gray-500">
          Soft master is powered by highly skilled professionals, engineers,
          developers, and executives equipped with the latest managerial and IT
          tools and works round-the-clock to assure the timely delivery of your
          applications with the highest quality.
        </p>
      </div>
      <div className="flex-center">
        <img src={illustartion} className="w-10/12 900px:w-10/12" />
      </div>
    </div>
  );
};

export default StaffsSection;
