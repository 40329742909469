import React from "react";
import Button from "../../../components/Button";
import logo from "../../../assets/images/logo.png";
import { Link } from "react-router-dom";
import src from "../../../assets/images/pattern-1.png";
import Map from "../../../components/Map";
import moment from "moment";
const Footer = (props) => {
  return (
    <section className="relative overflow-hidden">
      {/* {{-- Newsletter --}} */}

      {/* {{-- footer --}} */}
      <div className="mt-6 text-white  bg-[#343351] border-t-2 border-gray-50 ">
        <div className="grid gap-6 py-8 1000px:gap-12 max-screen-width 550px:grid-cols-2 1000px:grid-cols-4">
          {/* {{-- 1st --}} */}
          <div>
            <img src={logo} className="w-32" />
            <p className="mt-2 leading-6 text-gray-300">
              Two decade old trusted partner in information technology with
              providing high quality and sophisticated software solution in Sri
              Lankan market place
            </p>
          </div>

          {/* {{-- 2nd --}} */}
          <div className="">
            <p className="mb-4 font-medium uppercase text-15px">explore</p>
            <div className="flex flex-col items-start text-gray-300">
              <Link
                to={"/gallery"}
                className="mb-1.5 t-all hover:text-secondary"
              >
                Gallery
              </Link>
              <Link to="/works" className="mb-1.5 t-all hover:text-secondary">
                Works
              </Link>
              <Link to="/clients" className="mb-1.5 t-all hover:text-secondary">
                Clients
              </Link>
              <Link to="/careers" className="mb-1.5 t-all hover:text-secondary">
                Careers
              </Link>
              <Link
                to={"/aboutus"}
                className="mb-1.5 t-all hover:text-secondary"
              >
                About Us
              </Link>
              <Link
                to={"/contactus"}
                className="mb-1.5 t-all hover:text-secondary"
              >
                Contact Us
              </Link>
            </div>
          </div>

          {/* {{-- 3rd --}} */}
          <div>
            <p className="mb-4 font-medium uppercase text-15px">Location</p>
            <Map className={"w-full h-32"} />
          </div>
          {/* {{-- 4th --}} */}
          <div>
            <p className="mb-4 font-medium uppercase text-15px">Contact Info</p>
            <div className="flex flex-col items-start text-gray-300">
              <p className="flex items-start mb-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6 mr-3"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"
                    clipRule="evenodd"
                  />
                </svg>
                No:07, George E De Silva Mawatha, Kandy, Sri Lanka.
              </p>
              <p className="flex items-center mb-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-4 h-4 mr-3"
                >
                  <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                  <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                </svg>
                info@softmastergroup.com
              </p>
              <p className="flex items-center mb-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-4 h-4 mr-3"
                >
                  <path
                    fillRule="evenodd"
                    d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                    clipRule="evenodd"
                  />
                </svg>
                Tel: 081 2204 130 / 081 4954 222
              </p>
              <p className="flex items-center mb-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-4 h-4 mr-3"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.875 1.5C6.839 1.5 6 2.34 6 3.375v2.99c-.426.053-.851.11-1.274.174-1.454.218-2.476 1.483-2.476 2.917v6.294a3 3 0 003 3h.27l-.155 1.705A1.875 1.875 0 007.232 22.5h9.536a1.875 1.875 0 001.867-2.045l-.155-1.705h.27a3 3 0 003-3V9.456c0-1.434-1.022-2.7-2.476-2.917A48.716 48.716 0 0018 6.366V3.375c0-1.036-.84-1.875-1.875-1.875h-8.25zM16.5 6.205v-2.83A.375.375 0 0016.125 3h-8.25a.375.375 0 00-.375.375v2.83a49.353 49.353 0 019 0zm-.217 8.265c.178.018.317.16.333.337l.526 5.784a.375.375 0 01-.374.409H7.232a.375.375 0 01-.374-.409l.526-5.784a.373.373 0 01.333-.337 41.741 41.741 0 018.566 0zm.967-3.97a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H18a.75.75 0 01-.75-.75V10.5zM15 9.75a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V10.5a.75.75 0 00-.75-.75H15z"
                    clipRule="evenodd"
                  />
                </svg>
                Fax: 081 2204 130
              </p>
              <div className="">
                <a
                  target={"_blank"}
                  href="https://www.facebook.com/SoftmasterTechnologies/"
                >
                  <svg
                    fill="rgb(209, 213, 219)"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="h-8 p-1 bg-black rounded t-all hover:bg-primary"
                  >
                    <path d="M16.403,9H14V7c0-1.032,0.084-1.682,1.563-1.682h0.868c0.552,0,1-0.448,1-1V3.064c0-0.523-0.401-0.97-0.923-1.005 C15.904,2.018,15.299,1.999,14.693,2C11.98,2,10,3.657,10,6.699V9H8c-0.552,0-1,0.448-1,1v2c0,0.552,0.448,1,1,1l2-0.001V21 c0,0.552,0.448,1,1,1h2c0.552,0,1-0.448,1-1v-8.003l2.174-0.001c0.508,0,0.935-0.381,0.993-0.886l0.229-1.996 C17.465,9.521,17.001,9,16.403,9z" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {{-- copyright --}} */}
      <div className="bg-[#34334a] ">
        <div className="px-4 py-4 flex-center font-muted">
          © 2003-{moment().format("YYYY")} Soft-Master Techonologies (Pvt) Ltd.
          All Rights Reserved.
        </div>
      </div>
    </section>
  );
};
export default Footer;
