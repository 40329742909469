import React, { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

const useScroll = (props) => {
  const scroll = (top = 0, behavior = "smooth") => {
    document.documentElement.scrollTo({ top, behavior });
  };

  return {
    scroll: scroll,
  };
};
export default useScroll;
