import React, { useContext, useEffect, useRef, useState } from "react";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import Textarea from "../../../../components/Textarea";

import Modal from "../../../../components/Modal";
import Overlay from "../../../../components/Overlay";
import SpinnerOveraly from "../../../../components/SpinnerOverlay";
import useHttp from "../../../../hooks/useHttp";
import AuthContext from "../../../../store/admin/AuthContext";

const MessageModal = (props) => {
  let message = props.message;
  return (
    <Modal
      show={props.show}
      title={`Message`}
      onClose={() => {
        props.onClose();
      }}
    >
      <div className="">
        <div className="relative ">
          {!message && <SpinnerOveraly />}
          {message && (
            <div className="grid 600px:grid-cols-2 gap-6">
              <Input
                model={message.name}
                className="600px:col-span-2"
                readOnly
                label="name"
              />
              <Input model={message.email} readOnly label="email" />
              <Input model={message.phone} readOnly label="phone" />

              <Textarea
                model={message.message}
                className="600px:col-span-2"
                readOnly
                label="message"
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default MessageModal;
