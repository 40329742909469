import React from "react";
import Alert from "@mui/material/Alert";

const MessageBox = ( props ) => {
  const type = props.type ?? "danger";
  if (type == "success") {
    return (
      <Alert variant="outlined" severity="success">
        {props.children}
      </Alert>
    );
  } else if (type == "danger") {
    return (
      <Alert variant="outlined" severity="error">
        {props.children}
      </Alert>
    );
  } else if (type == "warning") {
    return (
      <Alert variant="outlined" severity="warning">
        {props.children}
      </Alert>
    );
  } else if (type == "info") {
    return (
      <Alert variant="outlined" severity="info">
        {props.children}
      </Alert>
    );
  }
};
export default MessageBox;
