import { Link } from "react-router-dom";
import test from "../assets/images/test.png";
import { Icon } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faSave,
  faTimes,
  faRedoAlt,
  faPlusCircle,
  faTimesCircle,
  faSyncAlt,
  faPrint,
  faAngleUp,
  faAngleDown,
  faSearch,
  faTrashAlt,
  faTrash,
  faPlayCircle,
  faMoneyBillWaveAlt,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

const ServiceCard = ({ service }) => {
  return (
    <Link
      to={`/services/${service.id}`}
      className="flex flex-col justify-between p-6 overflow-hidden transition-all duration-300 transform bg-gray-100 rounded cursor-pointer group hover:shadow-2xl hover:bg-primary hover:text-white"
    >
      <div>
        {/* <div
          // dangerouslySetInnerHTML={{ __html: service.icon }}
          className="service_icon w-14 h-14 text-primary group-hover:text-white"
        > */}

        {/* </div> */}

        <div>
          {/* <span>
            <FontAwesomeIcon icon="fa-solid fa-folder-open" />
          </span> */}
          <h1 className="text-[24px] capitalize font-bold mt-4">
            {service.name}
          </h1>
          <div className="grid gap-0.5 grid-flow-col items-start w-fit">
            <div className="w-8 h-1 rounded-full bg-primary group-hover:bg-white "></div>
            <div className="w-2 h-1 rounded-full bg-primary group-hover:bg-white "></div>
            <div className="w-4 h-1 rounded-full bg-primary group-hover:bg-white "></div>
          </div>
        </div>
        <p className="mt-4 text-sm leading-6 text-gray-500 group-hover:text-gray-100">
          {service.description.slice(0, 100)}...
        </p>
      </div>
      <div className="relative flex items-center w-full mt-4">
        <p className="overflow-hidden text-gray-100  group-hover:text-white text-15px whitespace-nowrap">
          Explore Works
        </p>
        <div className="absolute top-0 left-0 flex items-center w-full h-full t-all group-hover:translate-x-28">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-8 h-8 p-2 text-white rounded-full group-hover:ml-2 bg-primary group-hover:bg-white group-hover:text-primary"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
            />
          </svg>
        </div>
      </div>
    </Link>
  );
};

export default ServiceCard;
