import { NavLink, Outlet } from "react-router-dom";
import PageTitle from "../../../../components/PageTitle";

const InboxLayout = (props) => {
  return (
    <div className="p-4 900px:p-6">
      <div className="flex items-center justify-between flex-wrap">
        <PageTitle className="mt-4 mr-10">Inbox</PageTitle>
      </div>
      <div className="flex flex-wrap items-start mt-8">
        <NavLink
          to={"/admin/inbox/pending"}
          className={({ isActive }) =>
            `t-all text-center rounded-full py-1.5 px-4 ${
              isActive
                ? "bg-primary  text-white "
                : "text-gray-500 hover:text-primary"
            }`
          }
        >
          Pending
        </NavLink>
        <NavLink
          to={"/admin/inbox/archived"}
          className={({ isActive }) =>
            `t-all text-center rounded-full py-1.5 px-4 ${
              isActive
                ? "bg-primary  text-white "
                : "text-gray-500 hover:text-primary"
            }`
          }
        >
          Archived
        </NavLink>
      </div>

      <Outlet />
    </div>
  );
};

export default InboxLayout;
