import logo2 from "../../../assets/images/logo2.png";

import Button from "../../../components/Button";
import Card from "../../../components/Card";

import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import { Grow } from "@mui/material";

const ToolbarLower = ({ setShowDrawer, showDrawer, services }) => {
  const [showServices, setShowServices] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const location = useLocation();

  const changeNavbarColor = () => {
    if (window.scrollY >= 180) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  return (
    <div
      className={`fixed top-0 left-0 z-20 w-full ${
        isScrolled
          ? "bg-white text-black border-b-1"
          : location.pathname == "/"
          ? "text-white bg-transparent "
          : "bg-white"
      }`}
    >
      {isScrolled && (
        <svg
          onClick={() =>
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            })
          }
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="fixed w-12 h-12 p-3 text-white rounded-full animate-bounce t-all hover:bg-black bg-primary bottom-6 right-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4.5 15.75l7.5-7.5 7.5 7.5"
          />
        </svg>
      )}
      <div className="py-3 flex-between max-screen-width">
        <img
          src={logo2}
          className={`h-12 850px:h-16 ${
            !isScrolled && location.pathname == "/" && "opacity-0"
          }`}
        />

        <svg
          onClick={() => setShowDrawer((prev) => !prev)}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className={`w-8 h-8 text-black 850px:hidden t-all hover:text-primary`}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
          />
        </svg>

        <div className="items-center hidden grid-flow-col gap-6 850px:grid">
          <Link
            to={"/"}
            className={`font-semibold t-all hover-link  ${
              !isScrolled && location.pathname == "/"
                ? "after:bg-third hover:text-third"
                : "after:bg-primary hover:text-primary"
            }`}
          >
            HOME
          </Link>
          <Link
            to={"/aboutus"}
            className={`font-semibold t-all hover-link  ${
              !isScrolled && location.pathname == "/"
                ? "after:bg-third hover:text-third"
                : "after:bg-primary hover:text-primary"
            }`}
          >
            ABOUT US
          </Link>
          <div
            className="relative "
            onMouseOver={() => setShowServices(true)}
            onMouseLeave={() => setShowServices(false)}
          >
            <p
              className={`font-semibold t-all hover-link  ${
                !isScrolled && location.pathname == "/"
                  ? "after:bg-third hover:text-third"
                  : "after:bg-primary hover:text-primary"
              }`}
            >
              SERVICES
            </p>
            <Grow in={showServices} style={{ transformOrigin: "50% 0 0" }}>
              <div className="absolute top-0 left-0 pt-8 text-gray-500">
                <div className="relative">
                  <Card className="p-0 border-none rounded-none w-44 ">
                    {services.map((service) => (
                      <Link
                        to={"services/" + service.id}
                        className="block px-2 py-2 font-medium border-transparent border-l-3 t-all hover:text-primary hover:border-primary"
                      >
                        {service.name}
                      </Link>
                    ))}
                  </Card>
                </div>
              </div>
            </Grow>
          </div>
          <Link
            to={"/careers"}
            className={`font-semibold t-all hover-link  ${
              !isScrolled && location.pathname == "/"
                ? "after:bg-third hover:text-third"
                : "after:bg-primary hover:text-primary"
            }`}
          >
            CAREERS
          </Link>
          <Link
            to={"/clients"}
            className={`font-semibold t-all hover-link  ${
              !isScrolled && location.pathname == "/"
                ? "after:bg-third hover:text-third"
                : "after:bg-primary hover:text-primary"
            }`}
          >
            CLIENTS
          </Link>
          <Link
            to={"/gallery"}
            className={`font-semibold t-all hover-link  ${
              !isScrolled && location.pathname == "/"
                ? "after:bg-third hover:text-third"
                : "after:bg-primary hover:text-primary"
            }`}
          >
            GALLERY
          </Link>

          <Button to="/contactus">CONTACT US</Button>
        </div>
      </div>
    </div>
  );
};

export default ToolbarLower;
