import MainTitle from "../../../../components/MainTitle";
import ServiceCard from "../../../../components/ServiceCard";
import Fade from "react-reveal/Fade";
const ServicesSection = ({ services }) => {
  return (
    <Fade bottom>
      <div className="max-screen-width  mt-8 mb-12">
        <MainTitle main={"Our Services"} secondary="Serivces" />
        <div className="grid gap-6 650px:grid-cols-2 900px:grid-cols-3 mt-6 ">
          {services.map((service) => (
            <ServiceCard key={service.id} service={service} />
          ))}
        </div>
      </div>
    </Fade>
  );
};

export default ServicesSection;
