import React from "react";

const TableHead = ({ className, ...props }) => {
  return (
    <th
      {...props}
      className={`border-b text-left px-4 py-2 capitalize text-gray-400 font-bold ${className}`}
    >
      {props.children}
    </th>
  );
};
export default TableHead;
