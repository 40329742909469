import axios from "axios";
import React, { useContext, useState } from "react";
import AlertContext from "../store/common/AlertContext";
import AuthContext from "../store/admin/AuthContext";
import { useNavigate } from "react-router-dom";

const useHttp = (errorHandle = true) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [statusCode, setStatusCode] = useState(null);

  const [validationErros, setValidationErros] = useState(null);
  const alertContext = useContext(AlertContext);
  const navigate = useNavigate();

  const sendRequest = async (config) => {
    try {
      setError(null);
      setValidationErros(null);
      setIsLoading(true);

      let response = await axios({
        method: config.method ?? "GET",
        // url: "http://127.0.0.1:8000/api" + config.url,
        url: "https://softmaster.lk/smtk_site_server/api" + config.url,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: config.token && "Bearer " + config.token,
          ...config.headers,
        },
        data: config.body,
        params: config.queryParams,
      });
      return response.data;
    } catch (error) {
      setError(error.response?.data?.message ?? error.message);
      setStatusCode(error.response.status);
      if (errorHandle) {
        if (error.response.status == 422) {
          setValidationErros(error.response.data);
        } else if (error.response.status == 404) {
          navigate("/404", {
            state: {
              code: 404,
              message: "Not Found",
            },
          });
        } else if (error.response.status == 403) {
          navigate("/error", {
            state: {
              code: 403,
              message: "Unauthorized",
            },
          });
        } else if (error.response.status == 401) {
          navigate("/error", {
            state: {
              code: 402,
              message: "Unauthenticated",
            },
          });
        } else if (error.response.status == 500) {
          alertContext.addAlert(
            "danger",
            "Server Error",
            "Something went wrong, Please try again"
          );
        } else {
          alertContext.addAlert(
            "danger",
            "Error Occured",
            error.response?.data?.message ?? error.message
          );
        }
      }
    } finally {
      setIsLoading(false);
    }
  };
  const resetValidationErrors = () => {
    setValidationErros(null);
  };

  const sendFormRequest = async (config) => {
    try {
      setError(null);
      setStatusCode(null);
      setValidationErros(null);
      setIsLoading(true);

      let response = await axios.postForm(
        // "http://127.0.0.1:8000/api" + config.url,
        "https://softmaster.lk/smtk_site_server/api" + config.url,
        config.body,
        {
          headers: {
            Accept: "application/json",
            Authorization: config.token && "Bearer " + config.token,
            ...config.headers,
          },
        }
      );

      return response.data;
    } catch (error) {
      setStatusCode(error.response.status);

      if (error.response.status == 422) {
        setValidationErros(error.response.data);
      } else {
        alertContext.addAlert(
          "danger",
          "Server Error",
          "Something went wrong, Please try again"
        );
      }
      setError(error.response.statusText);
    } finally {
      setIsLoading(false);
    }
  };
  return {
    isLoading,
    error,
    validationErros,
    sendRequest,
    resetValidationErrors,
    sendFormRequest,
    statusCode,
  };
};
export default useHttp;
