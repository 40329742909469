export const TASK_STATUS_PENDING = "pending";
export const TASK_STATUS_ARCHIVED = "archived";

export const ADDRESS_STATUS = {
  BILLING: "billing",
  SHIPPING: "shipping",
};

export const imageUrl = (uri) => {
  return "https://softmaster.lk/smtk_site_server/storage/" + uri;
  // return "http://127.0.0.1:8000/storage/" + uri;
};

export const merge_formdata = (data1, data2) => {
  for (var pair of data2.entries()) {
    data1.append(pair[0], pair[1]);
  }
  return data1;
};

export const searchParamsToObject = (searchParams) => {
  let object = {};
  for (const [key, value] of searchParams.entries()) {
    object[key] = value;
  }
  return object;
};

export const removeQueryParam = (key, queryParams) => {
  let searchParams = new URLSearchParams(queryParams.replace("?", ""));
  searchParams.delete(key);
  return searchParams.toString();
};
