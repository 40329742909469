import React from "react";
import { useLocation } from "react-router-dom";
import Button from "../../components/Button";
import Card from "../../components/Card";

const Error = (props) => {
  const location = useLocation();
  return (
    <div className="flex-center h-full">
      <Card className="flex-center grid gap-2 p-6 w-[500px] max-w-[95%] ">
        <p className="text-8xl font-bold text-primary ">
          {location?.state?.code ?? 404}
        </p>
        <p className="mb-6 text-center">
          {location?.state?.message ?? "Requested page is not found"}
        </p>
        <Button to="/">Go Home</Button>
      </Card>
    </div>
  );
};
export default Error;
