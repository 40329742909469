import React, { useEffect, useRef, useState } from "react";

import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import Modal from "../../../../components/Modal";
import { imageUrl } from "../../../../utils/Statics";

let mainOptions = { type: "loop", arrows: false, pagination: false };
let thumbOptions = {
  fixedWidth: 120,
  fixedHeight: 100,
  gap: 10,
  rewind: true,
  arrows: false,
  pagination: false,
  cover: true,
  isNavigation: true,
  mediaQuery: "min",
};
const AlbumModal = (props) => {
  const [album, setAlbum] = useState(null);
  let thumbRef = useRef();
  let mainRef = useRef();

  useEffect(() => {
    if (mainRef.current) {
      mainRef.current.sync(thumbRef.current.splide);
    }
  }, [album]);
  useEffect(() => {
    setAlbum(props.album);
  }, [props.album]);

  return (
    <Modal
      show={props.show}
      title={props.album?.name}
      onClose={() => {
        props.onClose();
      }}
      className="w-full h-full 600px:w-11/12 max-w-none 600px:h-auto"
    >
      <div>
        {/* Main Image */}
        <div className="relative h-fit">
          {/* {{-- < --}} */}
          <div
            onClick={() => mainRef.current.go("<")}
            className="absolute top-0 left-0 z-10 h-full flex-center"
          >
            <div className="w-8 h-8 mb-2 ml-3 text-gray-500 bg-gray-100 rounded-full all-shadow flex-center t-all hover:text-white hover:bg-primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-0.5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </div>
          </div>

          {/* {{-- > --}} */}
          <div
            onClick={() => mainRef.current.go(">")}
            className="absolute top-0 right-0 z-10 h-full flex-center"
          >
            <div className="w-8 h-8 mb-4 mr-3 text-gray-500 bg-gray-100 rounded-full all-shadow flex-center t-all hover:text-white hover:bg-primary ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 ml-0.5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </div>
          </div>

          <Splide options={mainOptions} ref={mainRef}>
            {album?.images.map((img) => (
              <SplideSlide
                key={img.id}
                className="w-full bg-black flex-center 800px:py-6 "
              >
                <img
                  src={imageUrl(img.path)}
                  alt="Image 1"
                  className=" max-w-[100%] max-h-[45vw] 750px:max-h-[35vw] 1000px:max-h-[400px]  object-cover mx-auto"
                />
              </SplideSlide>
            ))}
          </Splide>
        </div>

        {/* thumbnail */}
        <Splide
          className="flex items-center justify-center mt-4 "
          options={thumbOptions}
          ref={thumbRef}
        >
          {album?.images.map((img) => (
            <SplideSlide key={img.id + "_thumb"} className=" thumb-img">
              <img src={imageUrl(img.path)} />
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </Modal>
  );
};
export default AlbumModal;
