import Card from "./Card";

const CompetitionKeyCard = ({ title, description, icon, color, className }) => {
  return (
    <Card
      className={`flex-center justify-start text-center t-all cursor-auto hover:-translate-y-1 ${className}`}
    >
      <div
        className={`w-[70px] h-[70px] p-3  text-white rounded-full mb-4 ${color}`}
        dangerouslySetInnerHTML={{ __html: icon }}
      ></div>

      <h1 className="font-semibold text-[18px] ">{title}</h1>
      <p className="text-center text-gray-400 mt-2 leading-7">{description}</p>
    </Card>
  );
};

export default CompetitionKeyCard;
