import { useEffect, useState } from "react";
import Breadcrumb from "../../../components/Breadcrumb";
import useHttp from "../../../hooks/useHttp";
import src from "../../../assets/images/no-results.png";
import PageSpinner from "../../../components/PageSpinner";
import ClientCard from "../../../components/ClientCard";
import SpinnerOveraly from "../../../components/SpinnerOverlay";

const Clients = (props) => {
  const [selected, setSelected] = useState(null);

  const [clients, setClients] = useState([]);
  const { error, isLoading, sendRequest } = useHttp();

  const fetch = async () => {
    const response = await sendRequest({
      url: "/clients/",
    });

    if (response != null) {
      setClients(response);
    }
  };
  useEffect(() => {
    fetch();
  }, []);
  return (
    <>
      <div className="">
        <Breadcrumb main={"Our Valuables"} secondary={"Clients"} />
        <div className="relative my-8 max-screen-width min-h-32">
          {isLoading && <SpinnerOveraly />}
          {clients.length == 0 && !isLoading && (
            <div className="mt-12 flex-center">
              <img src={src} className="h-20" />
              <h1 className="mt-4 mb-1 text-base font-semibold text-gray-900 ">
                Sorry No Client Found :(
              </h1>
              <p className="text-gray-400">
                We're sorry, we have no client for now
              </p>
            </div>
          )}
          <div className="grid gap-8 my-8 500px:grid-cols-2 950px:grid-cols-3 1200px:grid-cols-5 ">
            {clients?.map((client) => (
              <ClientCard client={client} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Clients;
