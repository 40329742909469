import React, { Fragment, useContext, useEffect } from "react";
import AuthContext from "../../store/admin/AuthContext";
import {
  Navigate,
  Outlet,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";

const IsSuperAdmin = (props) => {
  const authContext = useContext(AuthContext);
  console.log(authContext);
  const location = useLocation();

  if (
    authContext.isAuthenticated &&
    authContext.abilities?.includes("role:super")
  ) {
    return <Outlet />;
  } else {
    return <Navigate to={"/error"} state={{ status: 404 }} />;
  }
};
export default IsSuperAdmin;
