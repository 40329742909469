import React, { useContext, useEffect, useRef, useState } from "react";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import Textarea from "../../../../components/Textarea";

import Modal from "../../../../components/Modal";
import Overlay from "../../../../components/Overlay";
import SpinnerOveraly from "../../../../components/SpinnerOverlay";
import useHttp from "../../../../hooks/useHttp";
import AuthContext from "../../../../store/admin/AuthContext";
import { log } from "util";

const AlbumModal = (props) => {
  const [name, setName] = useState("");
  const [images, setImages] = useState([]);

  const { error, isLoading, sendFormRequest, validationErros } = useHttp();
  const authContext = useContext(AuthContext);

  useEffect(() => {
    const getAlbum = async () => {
      if (props.album != null) {
        setValues(props.album);
      }
    };
    getAlbum();
  }, [props.album]);

  const onSubmit = async (event) => {
    event.preventDefault();

    let formData = new FormData();
    formData.append("name", name);
    console.log(images);
    Array.from(images).forEach((img) => {
      formData.append("images[]", img);
    });

    if (props.album) {
      formData.append("_method", "PATCH");
    }

    const response = await sendFormRequest({
      url: props.album ? "/admin/albums/" + props.album.id : "/admin/albums",
      body: formData,
      token: authContext.token,
    });

    if (response != null) {
      resetValues();

      props.addAlert(
        "success",
        props.album ? "Updated" : "Created",
        `Album ${props.album ? "Updated" : "Created"} Successfully`
      );
      props.onComplete();
    }
  };

  const resetValues = () => {
    setName("");
    setImages([]);
  };

  const setValues = (album) => {
    setName(album.name);
    setImages([]);
  };

  return (
    <Modal
      show={props.show}
      title={`${props.album ? "Update" : "Create"} Album`}
      onClose={() => {
        resetValues();
        props.onClose();
      }}
    >
      <form onSubmit={onSubmit} className="">
        <div className="relative grid gap-4">
          {isLoading && !error && <Overlay />}
          <Input
            model={name}
            set={setName}
            label="name"
            error={validationErros?.errors?.name ?? null}
          />

          <Input
            model={images}
            multiple
            onChange={(e) => {
              let files = e.target.files;
              setImages(files);
            }}
            label={`${props.album ? "New" : ""} Album imges`}
            error={validationErros?.errors?.images ?? null}
            type="file"
            accept="image"
          />
        </div>
        <div className="flex-center mt-4">
          <Button isLoading={isLoading}>
            {props.album ? "Update" : "Create"} Album
          </Button>
        </div>
      </form>
    </Modal>
  );
};
export default AlbumModal;
