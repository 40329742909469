import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import Card from "../../../components/Card";
import Input from "../../../components/Input";
import useHttp from "../../../hooks/useHttp";
import AuthContext from "../../../store/admin/AuthContext";

const Register = (props) => {
  const authContext = useContext(AuthContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const { error, isLoading, sendRequest, validationErros } = useHttp();
  const onRegister = async (event) => {
    event.preventDefault();
    let response = await sendRequest({
      url: "/admins/register",
      method: "POST",
      body: {
        name: name,
        password: password,
        email: email,
      },
    });

    if (response) {
      authContext.onLoggedIn(response.token, response.user, response.abilities);
      navigate("/admin/inbox/pending");
    }
  };

  const getAdminCount = async () => {
    let response = await sendRequest({
      url: "/admins/count",
    });

    if (response != 0) {
      // navigate("/admin/authentication/login");
    }
  };

  useEffect(() => {
    getAdminCount();
  }, []);

  return (
    <div className="h-full w-96 ">
      <div className="w-full">
        <h1 className="mb-4 text-xl font-bold text-left">Register</h1>
        <form onSubmit={onRegister} className="grid w-full gap-4">
          <Input
            label="Name"
            model={name}
            set={setName}
            error={validationErros?.errors?.name ?? null}
          />
          <Input
            label="Email"
            model={email}
            set={setEmail}
            error={validationErros?.errors?.email ?? null}
          />
          <Input
            label="Password"
            type="password"
            model={password}
            set={setPassword}
            error={validationErros?.errors?.password ?? null}
          />
          <Button isLoading={isLoading}>Register</Button>
          <p className="">
            Already Have Account ?{" "}
            <Link className="link" to={"/admin/authentication/login"}>
              Login
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default Register;
